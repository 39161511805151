import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import Navbars from "../Components/Navbars";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import ButtonUp from "../Components/ButtonUp";
import Container from "../Components/Container";
import AppTop from "../Assets/app image top.png";
import AppSection1 from "../Assets/app section.png";
import AppUi1 from "../Assets/app ui 1.svg";
import AppUi2 from "../Assets/app ui 2.svg";
import AppUi3 from "../Assets/app ui 3.svg";
import AppLeft from "../Assets/app left.png";
import AppRight from "../Assets/app right.png";
import AppCheckmark from "../Assets/app checkmark.png";
import AppSectionSeo from "../Assets/app section seo.png";
import AppSectionBottom from "../Assets/app section bottom.png";
import { Helmet } from "react-helmet-async";
import { useLanguage } from "../Context/LanguageContext";

export default function ITApp() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - IT App"
            : "PT. Meister Sinergi Indonesia - IT 앱"}
        </title>
      </Helmet>
      <Navbars textblack currentBold={2} />
      <Container className={"pb-28 pt-20"}>
        <div className="flex flex-col space-y-8 space-x-0 lg:flex-row lg:space-x-12 lg:space-y-0 justify-center items-center">
          <img
            src={AppTop}
            className="w-full lg:w-1/3"
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
            alt="illustration"
          />
          <div className="w-full lg:w-1/2 lg:text-start text-center">
            <h1 className="font-bold text-4xl lg:text-5xl mb-8 leading-tight">
              {language === "ENG"
                ? "Leading Mobile App Development Studio"
                : "선도적인 모바일 앱 개발 스튜디오"}
            </h1>
            <p className="font-normal text-xl">
              {language === "ENG"
                ? "We build custom software that allows businesses to meet their needs and constraints."
                : "우리는 기업의 요구 사항과 제약 사항을 충족할 수 있는 맞춤형 소프트웨어를 구축합니다."}
            </p>
          </div>
        </div>
      </Container>
      <div className="bg-[#e7f4f1]">
        <div className="flex flex-col lg:flex-row items-center">
          <Container className={"py-14 lg:py-28 w-full"}>
            <div className="2xl:ml-40">
              <h1 className="font-bold text-4xl lg:text-5xl mb-8">
                {language === "ENG"
                  ? "Make it simple, but significant."
                  : "간단하면서도 의미 있게 만드세요."}
              </h1>
              <p className="mb-12">
                {language === "ENG"
                  ? "The public is more familiar with bad design than good design. It is, in effect, conditioned to prefer bad design."
                  : "대중은 좋은 디자인보다 나쁜 디자인에 더 익숙하다. 사실상 그것은 나쁜 디자인을 선호하도록 조건화되어 있습니다."}
              </p>
              <a href="/contact-us">
                <button className="px-6 py-2 bg-[#005D8D] text-white hover:bg-opacity-75">
                  {language === "ENG" ? "LET'S START" : "시작하자"}
                </button>
              </a>
            </div>
          </Container>
          <div>
            <img
              src={AppSection1}
              className="w-full"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              alt="illustration"
            />
          </div>
        </div>
      </div>
      <div className="py-28">
        <Container>
          <h3 className="text-center font-bold text-2xl mb-8 text-[#005D8D]">
            {"//"}
            {language === "ENG" ? " LATEST CASE STUDIES" : " 최신 사례 연구"}
          </h3>
          <h1 className="text-center font-bold text-4xl lg:text-5xl mb-8 leading-tight">
            {language === "ENG" ? "Case Studies" : "사례 연구"}
          </h1>
          <p className="text-center w-full lg:w-3/4 mx-auto text-xl">
            {language === "ENG"
              ? "We help businesses from healthcare, transportation, real estate, communication, and other industries seize new opportunities, overcome business challenges."
              : "우리는 의료, 운송, 부동산, 통신 및 기타 산업 분야의 기업이 새로운 기회를 포착하고 비즈니스 과제를 극복하도록 돕습니다."}
          </p>
          <div className="bg-[#5A5AFF] p-8 lg:p-16 flex flex-col-reverse lg:flex-row items-start lg:items-center lg:space-x-8 rounded-xl shadow-xl mt-20">
            <div className="flex flex-col items-start text-white space-y-8 w-full mt-8 lg:mt-0">
              <h2 className="px-8 py-2 bg-[#adadff] rounded-full font-bold">
                {language === "ENG" ? "App" : "앱"}
              </h2>
              <h1 className="font-bold text-4xl lg:text-5xl leading-tight">
                {language === "ENG"
                  ? "Application for Buying Tickets Online"
                  : "온라인 티켓 구매 신청"}
              </h1>
              <p className="text-xl">
                {language === "ENG"
                  ? "Our software development center provides you with the best resources, expertise."
                  : "우리의 소프트웨어 개발 센터는 최고의 리소스와 전문 지식을 제공합니다."}
              </p>
              <div className="flex flex-row items-center space-x-2 font-bold">
                <span>{language === "ENG" ? "SEE DETAILS" : "자세히보다"}</span>
                <FaArrowRight />
              </div>
            </div>
            <div className="w-full">
              <img
                src={AppUi1}
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="UI"
              />
            </div>
          </div>
          <div className="bg-[#CEFCF0] p-8 lg:p-16 flex flex-col lg:flex-row items-start lg:items-center lg:space-x-8 rounded-xl shadow-xl mt-20">
            <div className="w-full">
              <img
                src={AppUi2}
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="UI"
              />
            </div>
            <div className="flex flex-col items-start space-y-8 w-full mt-8 lg:mt-0">
              <h2 className="px-8 py-2 bg-[#39BCA4] rounded-full font-bold text-white">
                {language === "ENG" ? "App" : "앱"}
              </h2>
              <h1 className="font-bold text-4xl lg:text-5xl leading-tight">
                {language === "ENG"
                  ? "Application for Buying Tickets Online"
                  : "온라인 티켓 구매 신청"}
              </h1>
              <p className="text-xl">
                {language === "ENG"
                  ? "Our software development center provides you with the best resources, expertise."
                  : "우리의 소프트웨어 개발 센터는 최고의 리소스와 전문 지식을 제공합니다."}
              </p>
              <div className="flex flex-row items-center space-x-2 font-bold">
                <span>{language === "ENG" ? "SEE DETAILS" : "자세히보다"}</span>
                <FaArrowRight />
              </div>
            </div>
          </div>
          <div className="bg-[#001036] p-8 lg:p-16 flex flex-col-reverse lg:flex-row items-start lg:items-center lg:space-x-8 rounded-xl shadow-xl mt-20">
            <div className="flex flex-col items-start text-white space-y-8 w-full mt-8 lg:mt-0">
              <h2 className="px-8 py-2 bg-[#4A4A83] rounded-full font-bold">
                {language === "ENG" ? "App" : "앱"}
              </h2>
              <h1 className="font-bold text-4xl lg:text-5xl leading-tight">
                {language === "ENG"
                  ? "Application for Buying Tickets Online"
                  : "온라인 티켓 구매 신청"}
              </h1>
              <p className="text-xl">
                {language === "ENG"
                  ? "Our software development center provides you with the best resources, expertise."
                  : "우리의 소프트웨어 개발 센터는 최고의 리소스와 전문 지식을 제공합니다."}
              </p>
              <div className="flex flex-row items-center space-x-2 font-bold">
                <span>{language === "ENG" ? "SEE DETAILS" : "자세히보다"}</span>
                <FaArrowRight />
              </div>
            </div>
            <div className="w-full">
              <img
                src={AppUi3}
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="UI"
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#f8f8f8] py-16 lg:py-28">
        <Container>
          <h3 className="font-bold text-xl lg:text-2xl mb-8 text-[#005D8D]">
            {"//"}
            {language === "ENG" ? " LET’S DISCUSS WITH US" : " 우리와 토론하자"}
          </h3>
          <h1 className="font-bold text-3xl lg:text-5xl mb-8 leading-tight">
            {language === "ENG" ? "Let our dedicated team" : "우리의 전담팀이"}
            <br />
            {language === "ENG" ? "take the responsibility" : "책임을 지다"}
          </h1>
          <p className="w-full lg:w-3/4 text-xl">
            {language === "ENG"
              ? "As App Developers we build apps and digital products from the ground up that impact lives, facilitate change and ultimately succeed."
              : "앱 개발자로서 우리는 삶에 영향을 미치고 변화를 촉진하며 궁극적으로 성공하는 앱과 디지털 제품을 처음부터 구축합니다."}
          </p>
          <div className="flex flex-col space-y-8 space-x-0 lg:flex-row lg:space-x-12 lg:space-y-0 justify-start my-10 lg:my-16">
            <div className="flex flex-col">
              <h3 className="font-bold text-2xl text-[#005D8D] mb-4">
                {language === "ENG" ? "What we do" : "우리가 하는 일"}
              </h3>
              <p className="text-xl  mb-8">
                {language === "ENG"
                  ? "Our software development center provides you with the best resources, expertise."
                  : "우리의 소프트웨어 개발 센터는 최고의 리소스와 전문 지식을 제공합니다."}
              </p>
              <img
                src={AppLeft}
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="UI"
              />
              <div className="flex flex-row items-center space-x-2 mt-4">
                <img
                  src={AppCheckmark}
                  className="w-[25px]"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Checkmark"
                />
                <p>
                  {language === "ENG"
                    ? "Upgrade your engineering capacity"
                    : "엔지니어링 역량을 업그레이드하세요"}
                </p>
              </div>
              <div className="flex flex-row items-center space-x-2 mt-2">
                <img
                  src={AppCheckmark}
                  className="w-[25px]"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Checkmark"
                />
                <p>
                  {language === "ENG"
                    ? "Employ global development work standards"
                    : "글로벌 개발 작업 표준 채택"}
                </p>
              </div>
              <div className="flex flex-row items-center space-x-2 mt-2">
                <img
                  src={AppCheckmark}
                  className="w-[25px]"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Checkmark"
                />
                <p>
                  {language === "ENG"
                    ? "Utilize cutting-edge technologies and tools"
                    : "최첨단 기술과 도구를 활용하세요"}
                </p>
              </div>
              <div className="flex flex-row items-center space-x-2 mt-2">
                <img
                  src={AppCheckmark}
                  className="w-[25px]"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Checkmark"
                />
                <p>
                  {language === "ENG"
                    ? "Maintains transparency throughout the project"
                    : "프로젝트 전반에 걸쳐 투명성을 유지합니다."}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <h3 className="font-bold text-2xl text-[#005D8D] mb-4">
                {language === "ENG"
                  ? "What you can do"
                  : "당신이 할 수 있는 일"}
              </h3>
              <p className="text-xl  mb-8">
                {language === "ENG"
                  ? "Our dedicated software development team enables you to do many things."
                  : "우리의 전담 소프트웨어 개발 팀은 귀하가 많은 일을 할 수 있도록 지원합니다."}
              </p>
              <img
                src={AppRight}
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="UI"
              />
              <div className="flex flex-row items-center space-x-2 mt-4">
                <img
                  src={AppCheckmark}
                  className="w-[25px]"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Checkmark"
                />
                <p>
                  {language === "ENG"
                    ? "Upgrade your engineering capacity"
                    : "엔지니어링 역량을 업그레이드하세요"}
                </p>
              </div>
              <div className="flex flex-row items-center space-x-2 mt-2">
                <img
                  src={AppCheckmark}
                  className="w-[25px]"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Checkmark"
                />
                <p>
                  {language === "ENG"
                    ? "Employ global development work standards"
                    : "글로벌 개발 작업 표준 채택"}
                </p>
              </div>
              <div className="flex flex-row items-center space-x-2 mt-2">
                <img
                  src={AppCheckmark}
                  className="w-[25px]"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Checkmark"
                />
                <p>
                  {language === "ENG"
                    ? "Utilize cutting-edge technologies and tools"
                    : "최첨단 기술과 도구를 활용하세요"}
                </p>
              </div>
              <div className="flex flex-row items-center space-x-2 mt-2">
                <img
                  src={AppCheckmark}
                  className="w-[25px]"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Checkmark"
                />
                <p>
                  {language === "ENG"
                    ? "Maintains transparency throughout the project"
                    : "프로젝트 전반에 걸쳐 투명성을 유지합니다."}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#f0f0f0] py-28">
        <Container
          className={
            "flex flex-col space-x-0 space-y-8 lg:flex-row items-center lg:space-x-8 lg:space-y-0"
          }
        >
          <div className="w-full text-center lg:text-start">
            <h1 className="font-bold text-4xl lg:text-5xl leading-tight mb-8">
              {language === "ENG"
                ? "The collect companies already working us"
                : "이미 우리를 고용하고 있는 수집 회사"}
            </h1>
            <p className="font-normal text-xl">
              {language === "ENG"
                ? "We partner with companies of all sizes, from startups to enterprises, to create bespoke digital products that solve problems."
                : "우리는 스타트업부터 대기업까지 모든 규모의 회사와 협력하여 문제를 해결하는 맞춤형 디지털 제품을 만듭니다."}
            </p>
          </div>
          <div className="w-full">
            <img
              src={AppSectionSeo}
              className="w-full"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              alt="Section SEO"
            />
          </div>
        </Container>
      </div>
      <div className="relative">
        <img
          src={AppSectionBottom}
          className="object-cover w-full h-[2750px] lg:h-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="Section Bottom"
        />
        <Container
          className={
            "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full"
          }
        >
          <div>
            <h3 className="font-bold text-2xl mb-8 text-[#ffe09d]">
              {"//"}
              {language === "ENG" ? " OUR APP DEVELOPMENT" : " 우리의 앱 개발"}
            </h3>
            <h1 className="font-bold text-4xl lg:text-5xl mb-8 leading-tight text-white">
              {language === "ENG"
                ? "How we develops top-notch apps"
                : "최고의 앱을 개발하는 방법"}
            </h1>
            <p className="w-full lg:w-3/4 text-xl text-white">
              {language === "ENG"
                ? "That has very much been the secret of our consistent success over the years. Below are a few steps of the process that we follow."
                : "이것이 바로 우리가 수년에 걸쳐 꾸준한 성공을 거둘 수 있었던 비결이었습니다. 다음은 우리가 따르는 프로세스의 몇 가지 단계입니다."}
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 my-12">
            <div className="bg-[#ffffff] bg-opacity-25 rounded-xl px-8 py-12 text-white">
              <span className="text-5xl border-b-4 border-[#ffe09d] my-8 w-full font-extrabold shadow-lg">
                01
              </span>
              <h2 className="text-2xl mt-8 mb-4 font-bold">
                {language === "ENG"
                  ? "Bring Together the Right People in Team"
                  : "팀에 적합한 사람들을 모으십시오."}
              </h2>
              <p className="font-normal">
                {language === "ENG"
                  ? "Assemble a cohesive team by bringing together individuals with the right skills and expertise."
                  : "적절한 기술과 전문 지식을 갖춘 개인을 모아 결속력 있는 팀을 구성하세요."}
              </p>
            </div>
            <div className="bg-[#ffffff] bg-opacity-25 rounded-xl px-8 py-12 text-white">
              <span className="text-5xl border-b-4 border-[#ffe09d] my-8 w-full font-extrabold shadow-lg">
                02
              </span>
              <h2 className="text-2xl mt-8 mb-4 font-bold">
                {language === "ENG"
                  ? "Efficient Sprint Planning"
                  : "효율적인 스프린트 계획"}
              </h2>
              <p className="font-normal">
                {language === "ENG"
                  ? "We plan an efficient sprint roadmap for team members to collaborate effectively."
                  : "팀원들이 효과적으로 협업할 수 있도록 효율적인 스프린트 로드맵을 계획합니다."}
              </p>
            </div>
            <div className="bg-[#ffffff] bg-opacity-25 rounded-xl px-8 py-12 text-white">
              <span className="text-5xl border-b-4 border-[#ffe09d] my-8 w-full font-extrabold shadow-lg">
                03
              </span>
              <h2 className="text-2xl mt-8 mb-4 font-bold">
                {language === "ENG"
                  ? "Code Tech Architecture"
                  : "코드테크 아키텍처"}
              </h2>
              <p className="font-normal">
                {language === "ENG"
                  ? "We decouple the codes which enable our team to work faster and independently."
                  : "우리 팀이 더 빠르고 독립적으로 작업할 수 있도록 코드를 분리합니다."}
              </p>
            </div>
            <div className="bg-[#ffffff] bg-opacity-25 rounded-xl px-8 py-12 text-white">
              <span className="text-5xl border-b-4 border-[#ffe09d] my-8 w-full font-extrabold shadow-lg">
                04
              </span>
              <h2 className="text-2xl mt-8 mb-4 font-bold">
                {language === "ENG"
                  ? "Iterative Delivery Approach"
                  : "반복적인 전달 접근 방식"}
              </h2>
              <p className="font-normal">
                {language === "ENG"
                  ? "We break the implementation process into smaller pieces to deliver value incrementally."
                  : "우리는 점진적으로 가치를 제공하기 위해 구현 프로세스를 더 작은 조각으로 나눕니다."}
              </p>
            </div>
            <div className="bg-[#ffffff] bg-opacity-25 rounded-xl px-8 py-12 text-white">
              <span className="text-5xl border-b-4 border-[#ffe09d] my-8 w-full font-extrabold shadow-lg">
                05
              </span>
              <h2 className="text-2xl mt-8 mb-4 font-bold">
                {language === "ENG" ? "Code Reviews" : "코드 리뷰"}
              </h2>
              <p className="font-normal">
                {language === "ENG"
                  ? "We identify issues like file leaks, memory leaks, and performance signs with code reviews."
                  : "우리는 코드 검토를 통해 파일 누수, 메모리 누수, 성능 징후와 같은 문제를 식별합니다."}
              </p>
            </div>
            <div className="bg-[#ffffff] bg-opacity-25 rounded-xl px-8 py-12 text-white">
              <span className="text-5xl border-b-4 border-[#ffe09d] my-8 w-full font-extrabold shadow-lg">
                06
              </span>
              <h2 className="text-2xl mt-8 mb-4 font-bold">
                {language === "ENG" ? "Standups and Demos" : "스탠드업 및 데모"}
              </h2>
              <p className="font-normal">
                {language === "ENG"
                  ? "Weekly reviews, demos, and standups ensure coordination among team members and the client."
                  : "주간 리뷰, 데모 및 스탠드업을 통해 팀 구성원과 고객 간의 조정이 보장됩니다."}
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="relative py-28">
        <Container
          className={
            "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[85%] lg:-translate-y-full w-full lg:w-3/4"
          }
        >
          <div className="bg-white shadow-xl p-8 rounded-xl w-full">
            <div className="flex flex-col space-x-0 space-y-8 lg:flex-row lg:space-y-0 lg:space-x-12 items-center justify-between">
              <div className="flex flex-col w-full lg:w-3/4">
                <h1 className="font-bold text-3xl lg:text-4xl mb-4">
                  {language === "ENG"
                    ? "Got a project or idea?"
                    : "프로젝트나 아이디어가 있나요?"}
                </h1>
                <h3 className="text-[#005D8D] font-bold text-xl">
                  {language === "ENG"
                    ? "Leave your details and we’ll be in touch within 24 hours."
                    : "세부정보를 남겨주시면 24시간 이내에 연락드리겠습니다."}
                </h3>
              </div>
              <a href="/contact-us">
                <button className="bg-[#005D8D] px-8 py-4 font-medium text-white rounded-xl hover:bg-opacity-75">
                  {language === "ENG" ? "GET IN TOUCH" : "연락하세요"}
                </button>
              </a>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
