import React, { useState } from "react";
import Navbars from "../Components/Navbars";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { FaCircleChevronLeft } from "react-icons/fa6";
import Container from "../Components/Container";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import ButtonUp from "../Components/ButtonUp";
import ImgAutocamBackground from "../Assets/autocam background.svg";
import ImgAutocamMenu from "../Assets/autocam menu.png";
import ImgAutocamSection1 from "../Assets/autocam background section 1.png";
import ImgAutocamLeft1 from "../Assets/autocam left 1.png";
import ImgAutocamRight1 from "../Assets/autocam right 1.png";
import ImgAutocamSection2 from "../Assets/autocam background section 2.svg";
import ImgAutocamLeft2 from "../Assets/autocam left 2.png";
import ImgAutocamRight2 from "../Assets/autocam right 2.png";
import ImgAutocamLeft3 from "../Assets/autocam left 3.png";
import ImgAutocamRight3 from "../Assets/autocam right 3.png";
import ImgAutocamRight4 from "../Assets/autocam right 4.png";
import ImgAutocamRight5 from "../Assets/autocam right 5.png";
import ImgAutocamSection4 from "../Assets/autocam background section 4.webp";
import ImgAutocamPerson1 from "../Assets/autocam person 1.png";
import ImgAutocamPerson2 from "../Assets/autocam person 2.png";
import ImgAutocamPerson3 from "../Assets/autocam person 3.png";
import ImgAutocamPerson4 from "../Assets/autocam person 4.png";
import ImgAutocamPerson5 from "../Assets/autocam person 5.png";
import ImgAutocamPerson6 from "../Assets/autocam person 6.png";
import ImgAutocamPerson7 from "../Assets/autocam person 7.png";
import ImgAutocamPerson8 from "../Assets/autocam person 8.png";
import ImgAutocamIllustration1 from "../Assets/autocam illustration 1.png";
import ImgAutocamIllustration2 from "../Assets/autocam illustration 2.png";
import { useLanguage } from "../Context/LanguageContext";
import { Helmet } from "react-helmet-async";

export default function CADAutocam() {
  const [activeTab, setActiveTab] = useState("1");
  const [activeTab2, setActiveTab2] = useState("1");
  const { language } = useLanguage();

  const data = [
    {
      label: "Existing (before circuit correction)",
      labelKOR: "기존 (회로 보정 전)",
      value: "1",
      image: ImgAutocamLeft1,
      desc: "In a circuit with a gap of 50-micron or less, a short defect occurred because correction was made to 50 microns.",
      descKOR: `50-micron 이하의 간격을 가진 회로에서 보정을 50미크론을 하였으므로 short 불량이 발생함`,
    },
    {
      label: "After circuit correction (pre-check after net check)",
      labelKOR: "회로 보정 후  (Net Check 후 사전 체크)",
      value: "2",
      image: ImgAutocamRight1,
      desc: "Result: Short checked correctly",
      descKOR: `결과 : Short 정확히 체크됨`,
    },
  ];

  const data2 = [
    {
      label: "Existing (before Annular ring)",
      labelKOR: "기존 (Annular ring 전)",
      value: "1",
      image: ImgAutocamLeft2,
      desc: "Set the Annular ring with Drill + 0.4 py and run it.",
      descKOR: `Drill + 0.4 파이로 Annular ring 설정 후 실행.`,
    },
    {
      label: "After Annular ring (pre-check after Net Check)",
      labelKOR: "Annular ring 후  (Net Check 후 사전 체크)",
      value: "2",
      image: ImgAutocamRight2,
      desc: "Result: Short checked correctly",
      descKOR: `결과 : Short 정확히 체크됨`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - CAD Autocam V2"
            : "PT. Meister Sinergi Indonesia - CAD 오토캠 V2"}
        </title>
      </Helmet>
      <Navbars textblack currentBold={3} />
      <div className="background-container">
        <img
          src={ImgAutocamBackground}
          alt="background"
          className="w-full"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
      </div>
      <Container className={"text-center my-28"}>
        <h1 className="text-4xl lg:text-5xl font-bold mb-4">
          {language === "ENG" ? "AutoCAM V2" : "오토캠 V2"}
        </h1>
        <h2 className="text-2xl lg:text-3xl font-bold mb-8 text-[#005D8D]">
          {"//"} {language === "ENG" ? "Entire Menu" : "전체 메뉴"}
        </h2>
        <p className="text-lg lg:text-xl mb-8">
          {language === "ENG"
            ? "Experience unparalleled precision with our AutoCAM V2 special settings."
            : "AutoCAM V2 특수 설정으로 비교할 수 없는 정밀도를 경험해 보세요."}
        </p>
        <img
          src={ImgAutocamMenu}
          alt="background"
          className="w-full lg:w-3/4 mx-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
      </Container>
      <div className="relative">
        <img
          src={ImgAutocamSection1}
          alt="background"
          className="object-cover w-full h-[1000px] lg:h-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
        <Container
          className={
            "py-20 w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          }
        >
          <h1 className="text-4xl lg:text-5xl font-bold mb-4">
            {language === "ENG" ? "Etch Compensation" : "에칭 보상"}
          </h1>
          <h2 className="text-2xl lg:text-3xl font-bold mb-8 text-[#005D8D]">
            {language === "ENG" ? "(Net Check)" : "(순수표)"}
          </h2>
          <Tabs value={activeTab}>
            <TabsHeader
              className="rounded-none bg-transparent p-0"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
            >
              {data.map(({ label, labelKOR, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className={
                    activeTab === value
                      ? "text-[#005D8D] font-bold text-lg !justify-start mr-8 border-t-4 border-[#005D8D]"
                      : "hover:opacity-75 text-[#005D8D] font-normal text-lg !justify-start mr-8 border-t-4 border-[#cacaca]"
                  }
                >
                  <h1 className="w-full py-2 text-left">
                    {language === "ENG" ? label : labelKOR}
                  </h1>
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {data.map(({ label, labelKOR, value, image, desc, descKOR }) => (
                <TabPanel key={value} value={value}>
                  <div className="bg-white flex flex-col space-x-0 space-y-4 lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0 px-8 py-12 rounded-xl shadow-lg">
                    <img
                      src={image}
                      alt="autocam left"
                      className="w-full lg:w-1/2"
                      onContextMenu={(e) => e.preventDefault()}
                      draggable="false"
                    />
                    <div className="w-full lg:w-1/2">
                      <h1 className="font-bold text-4xl lg:text-5xl text-[#1e1e1e]">
                        {language === "ENG" ? label : labelKOR}
                      </h1>
                      <p className="text-xl my-8">
                        {language === "ENG" ? desc : descKOR}
                      </p>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </Container>
      </div>
      <div className="relative">
        <img
          src={ImgAutocamSection2}
          alt="background"
          className="object-cover w-full h-[900px] lg:h-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
        <Container
          className={
            "py-20 w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          }
        >
          <h1 className="text-4xl lg:text-5xl font-bold mb-4 text-white">
            {language === "ENG" ? "Annularing" : "환형화"}
          </h1>
          <h2 className="text-2xl lg:text-3xl font-bold mb-8 text-white">
            {language === "ENG" ? "(Net Check)" : "(순수표)"}
          </h2>
          <Tabs value={activeTab2}>
            <TabsHeader
              className="rounded-none bg-transparent p-0"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
            >
              {data2.map(({ label, labelKOR, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab2(value)}
                  className={
                    activeTab2 === value
                      ? "text-white font-bold text-lg !justify-start mr-8 border-t-4 border-[#f2c96f]"
                      : "opacity-75 text-white font-normal text-lg !justify-start mr-8 border-t-4 border-[#cacaca]"
                  }
                >
                  <h1 className="w-full py-2 text-left">
                    {language === "ENG" ? label : labelKOR}
                  </h1>
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {data2.map(({ label, labelKOR, value, image, desc, descKOR }) => (
                <TabPanel key={value} value={value}>
                  <div className="bg-white bg-opacity-25 flex flex-col space-y-4 space-x-0 lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0 px-8 py-12 rounded-xl shadow-lg">
                    <img
                      src={image}
                      alt="autocam left"
                      className="w-full lg:w-1/2"
                      onContextMenu={(e) => e.preventDefault()}
                      draggable="false"
                    />
                    <div className="w-full lg:w-1/2 text-white">
                      <h1 className="font-bold text-4xl lg:text-5xl">
                        {language === "ENG" ? label : labelKOR}
                      </h1>
                      <p className="text-xl my-8">
                        {language === "ENG" ? desc : descKOR}
                      </p>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </Container>
      </div>
      <div className="bg-white">
        <Container className="flex flex-col lg:flex-row justify-start">
          <div className="w-full py-28 bg-[#FFF5E0] bg-opacity-70">
            <Container>
              <h1 className="text-5xl font-bold mb-4">
                {language === "ENG" ? "AutoCAM V2" : "오토캠 V2"}
              </h1>
              <h2 className="text-[#005D8D] text-3xl font-bold mb-8">
                {language === "ENG" ? "(Netlist Check)" : "(넷리스트 확인)"}
              </h2>
              <span className="bg-white px-4 py-2 rounded-xl shadow-lg text-[#005D8D] font-semibold">
                {language === "ENG" ? "Before check" : "체크 전"}
              </span>
              <img
                src={ImgAutocamLeft3}
                alt="autocam left"
                className="w-full my-8 mx-auto"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <p className="text-xl mb-4">
                {language === "ENG"
                  ? "Quick detection with pre-check (Open, Short, Min Width Wire) No more anxiety for cam employees"
                  : "사전체크로 빠른 검출 (Open, Short, Min Width Wire) 캠 직원의 불안감 없어짐"}
              </p>
              <ul>
                <li className="custom-list-item2">
                  {language === "ENG"
                    ? "Elimination of hiring difficulties due to frequent personnel changes, compliance with deadlines"
                    : "잦은 인원교체로 인한 구인난 없어짐, 납기 준수"}
                </li>
                <li className="custom-list-item2">
                  {language === "ENG"
                    ? "Resulting in increased sales"
                    : "매출 증대로 귀결됨"}
                </li>
              </ul>
            </Container>
          </div>
          <div className="w-full py-28 text-right bg-[#BFD9E7] bg-opacity-50">
            <Container>
              <span className="bg-[#f7fff5] bg-opacity-60 px-4 py-2 rounded-xl shadow-lg text-[#005D8D] font-semibold">
                {language === "ENG" ? "After checking" : "체크 후"}
              </span>
              <h1 className="text-5xl font-bold my-8">
                {language === "ENG" ? "COMPARE NET" : "순 비교"}
              </h1>
              <div className="flex flex-row items-center justify-end space-x-2 mb-4">
                <p>
                  {language === "ENG"
                    ? "Start[C:ACT_9o|Scripts|turn_on.scr]"
                    : "시작[C:ACT_9o|Scripts|turn_on.scr]"}
                </p>
                <FaCircleChevronLeft className="text-[#005D8D]" />
              </div>
              <div className="flex flex-row items-center justify-end space-x-2">
                <p>
                  {language === "ENG"
                    ? "1st OPEN review result"
                    : "1차 OPEN 검토 결과"}
                </p>
                <FaCircleChevronLeft className="text-[#005D8D]" />
              </div>
              <img
                src={ImgAutocamRight3}
                alt="autocam right"
                className="w-full mt-2 mb-6 mx-auto"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <div className="flex flex-row items-center justify-end space-x-2">
                <p>
                  {language === "ENG"
                    ? "1st SHORT review results"
                    : "1차 SHORT 검토 결과"}
                </p>
                <FaCircleChevronLeft className="text-[#005D8D]" />
              </div>
              <img
                src={ImgAutocamRight4}
                alt="autocam right"
                className="w-full mt-2 mb-6 mx-auto"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <div className="flex flex-row items-center justify-end space-x-2">
                <p>
                  {language === "ENG"
                    ? "2nd Minimum Circuit Review Results"
                    : "2차 최소 회로 검토결과"}
                </p>
                <FaCircleChevronLeft className="text-[#005D8D]" />
              </div>
              <img
                src={ImgAutocamRight5}
                alt="autocam right"
                className="w-full mt-2 mb-6 mx-auto"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            </Container>
          </div>
        </Container>
      </div>
      <div className="relative">
        <img
          src={ImgAutocamSection4}
          alt="section autocam"
          className="object-cover w-full h-[200px] lg:h-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[0%] bg-white shadow-xl p-8 rounded-xl z-10 w-[90%] lg:w-auto">
          <h3 className="text-[#005D8D] font-bold text-2xl mb-8">
            {language === "ENG" ? "CONTACT US" : "문의하기"}
          </h3>
          <h1 className="font-bold text-3xl lg:text-4xl mb-8">
            {language === "ENG"
              ? "Do you have any questions?"
              : "질문있으세요?"}
          </h1>
          <h3 className="text-[#cfcfcf] font-bold text-3xl mb-4">
            {language === "ENG" ? "Type here..." : "여기에 입력하세요..."}
          </h3>
          <div className="flex flex-col lg:flex-row items-start space-y-2 lg:space-y-0">
            <input
              placeholder="Enter your email address"
              className="bg-[#f3f3f3] p-4 w-full lg:w-1/2 mr-4 grow"
            />
            <button className="bg-[#005D8D] px-8 py-4 text-white lg:w-auto self-end">
              {language === "ENG" ? "SEND" : "보내다"}
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white pt-96 lg:pt-80 pb-28">
        <Container
          className={"flex flex-col space-y-4 lg:flex-row lg:space-y-0"}
        >
          <div className="w-full lg:w-1/2">
            <h1 className="font-bold text-4xl lg:text-5xl mb-8">
              {language === "ENG" ? "AutoCAM V2" : "오토캠 V2"}
            </h1>
            <h2 className="text-[#005D8D] font-bold text-3xl">
              {language === "ENG"
                ? "(Company G empirical case)"
                : "(G社 실증사례)"}
            </h2>
          </div>
          <div className="w-full lg:w-1/2">
            <p className="text-xl">
              {language === "ENG"
                ? "Our case studies showcase tangible results, illustrating how G Company solutions drive efficiency, innovation, and measurable success in diverse applications."
                : "우리의 사례 연구는 G Company 솔루션이 어떻게 다양한 애플리케이션에서 효율성, 혁신 및 측정 가능한 성공을 주도하는지 보여주는 실질적인 결과를 보여줍니다."}
            </p>
            <div className="my-12">
              <h1 className="text-[#BFD9E7] text-9xl text-right font-bold">
                01
              </h1>
              <h2 className="text-[#005D8D] font-bold text-3xl text-right">
                {language === "ENG" ? "headcount reduction" : "인원 절감"}
              </h2>
            </div>
          </div>
        </Container>
        <Container
          className={
            "flex flex-col space-x-0 space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0"
          }
        >
          <div className="flex flex-col justify-between items-center bg-white pb-12 shadow-xl w-full lg:w-1/2">
            <span className="self-center lg:self-start text-3xl px-4 py-2 font-bold text-white bg-[#005D8D]">
              {language === "ENG" ? "Before introduction" : "도입 전"}
            </span>
            <div className="grid grid-cols-3 items-center justify-center gap-4 my-12 mx-8">
              <img
                src={ImgAutocamPerson1}
                alt="person autocam"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <img
                src={ImgAutocamPerson2}
                alt="person autocam"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <img
                src={ImgAutocamPerson3}
                alt="person autocam"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <img
                src={ImgAutocamPerson4}
                alt="person autocam"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <img
                src={ImgAutocamPerson5}
                alt="person autocam"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <img
                src={ImgAutocamPerson6}
                alt="person autocam"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            </div>
            <div className="text-xl my-1 text-center">
              <p>
                {language === "ENG"
                  ? "Before AutoCAM: 6 cam people x"
                  : "AutoCAM 이전 : 캠 인원 6명 x"}
              </p>
              <p>
                {language === "ENG"
                  ? "Overtime (1.5 times) until 22:00 on average and review of defects"
                  : "평균 22시까지 특근(1.5배) 하며 불량 검토함"}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between items-center bg-white pb-12 shadow-xl w-full lg:w-1/2">
            <span className="self-center lg:self-start text-3xl px-4 py-2 font-bold text-white bg-[#005D8D] text-center">
              {language === "ENG" ? "After introduction" : "도입 후"}
            </span>
            <div className="grid grid-cols-2 w-1/2 gap-4 my-12 mx-8">
              <img
                src={ImgAutocamPerson7}
                alt="person autocam"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <img
                src={ImgAutocamPerson8}
                alt="person autocam"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            </div>
            <div className="text-xl my-2 text-center">
              <p>
                {language === "ENG"
                  ? "After AutoCAM: 2 cam people x"
                  : "AutoCAM 이후 : 캠 인원 2명 x"}
              </p>
              <p>
                {language === "ENG"
                  ? "Convenient review until 19:00 on average, increased work efficiency, no more recruitment difficulties"
                  : "평균 19시까지 편히 검토 업무효율 UP, 구인난 없어짐"}
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#BFD9E7] py-20 w-full">
        <Container>
          <div className=" flex flex-col space-y-8 lg:flex-row lg:space-y-0">
            <div className="w-full">
              <p className="text-xl">
                {language === "ENG"
                  ? "Experience streamlined operations with our solutions, cutting work hours to boost productivity. Unlock time savings and propel your business into a more agile and effective future."
                  : "당사의 솔루션으로 간소화된 운영을 경험하고 작업 시간을 단축하여 생산성을 높이십시오. 시간을 절약하고 비즈니스를 보다 민첩하고 효과적인 미래로 발전시키세요."}
              </p>
            </div>
            <div className="self-end w-full lg:w-1/2">
              <h1 className="text-white text-8xl lg:text-9xl text-right font-bold">
                02
              </h1>
              <h2 className="text-[#005D8D] font-bold text-3xl text-right">
                {language === "ENG" ? "Shorten work hours" : "업무 시간 단축"}
              </h2>
            </div>
          </div>
          <div className="flex flex-col space-y-8 space-x-0 lg:flex-row justify-center lg:space-x-8 lg:space-y-0 my-12 lg:my-20">
            <div className="flex flex-col items-center bg-white pb-12 shadow-xl w-full">
              <span className="self-start text-2xl px-4 py-2 font-bold text-white mb-8 bg-[#005D8D] text-center">
                {language === "ENG" ? "Before introduction" : "도입 전"}
              </span>
              <img
                src={ImgAutocamIllustration1}
                alt="Illustration autocam"
                className="w-[60%] lg:w-1/3 h-auto grow"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            </div>
            <div className="flex flex-col items-center bg-white pb-12 shadow-xl w-full">
              <span className="self-start text-2xl px-4 py-2 font-bold text-white mb-8 bg-[#005D8D] text-center">
                {language === "ENG" ? "After introduction" : "도입 후"}
              </span>
              <img
                src={ImgAutocamIllustration2}
                alt="Illustration autocam"
                className="w-[60%] lg:w-1/3 h-auto grow"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-white py-28">
        <Container
          className={"flex flex-col space-y-8 lg:flex-row lg:space-y-0"}
        >
          <div className="w-full">
            <h3 className="text-[#005D8D] font-bold text-2xl lg:text-3xl mb-4">
              {"//"}
              {language === "ENG" ? " WHAT WE OFFER" : " 우리가 제공하는 것"}
            </h3>
            <h1 className="font-bold text-4xl lg:text-5xl leading-snug">
              {language === "ENG"
                ? "Your partner for CAD/CAM service"
                : "CAD/CAM 서비스를 위한 파트너"}
            </h1>
          </div>
          <div className="w-full text-justify">
            <p className="text-xl mb-8">
              {language === "ENG"
                ? "PT. Meister Sinergi Indonesia is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through high accuracy and high quality product."
                : "PT. Meister Sinergi Indonesia는 세계 최고의 기업, 중소기업 및 기술 도전자들이 선택하는 파트너입니다. 고정밀도, 고품질의 제품을 통해 기업의 가치를 높일 수 있도록 돕습니다."}
            </p>
            <p className="text-xl font-semibold italic">
              {language === "ENG"
                ? "We can help to ease your extensive work to have a high quality product with a high precission accuracy within a favorable time."
                : "우리는 귀하의 광범위한 작업을 용이하게 하여 유리한 시간 내에 고정밀 정확도의 고품질 제품을 확보할 수 있도록 도와드릴 수 있습니다."}
            </p>
          </div>
        </Container>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
