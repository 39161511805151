import React from "react";
import Navbars from "../Components/Navbars";
import ImgHomepage from "../Assets/new background homepage 2.svg";
import Container from "../Components/Container";
import IconIT from "../Assets/1. icon it development.svg";
import IconPCB from "../Assets/2. icon pcb design.svg";
import IconCAM from "../Assets/3. icon cam analytics.svg";
import IconQA from "../Assets/4. icon qa testing.svg";
import IconBEST from "../Assets/5. icon best team.svg";
import ImgEnvironment from "../Assets/homepage environment.png";
import ImgSociety from "../Assets/homepage society.png";
import ImgGovernment from "../Assets/homepage government.png";
import ImgWorldMap from "../Assets/world map.png";
import ImgTeamwork from "../Assets/homepage teamwork 8.png";
import ImgTeamwork1 from "../Assets/homepage teamwork 1.png";
import ImgTeamwork2 from "../Assets/homepage teamwork 2.png";
import ImgTeamwork3 from "../Assets/homepage teamwork 3.png";
import ImgTeamwork4 from "../Assets/homepage teamwork 4.png";
import { Carousel } from "@material-tailwind/react";
import ImgCube from "../Assets/homepage cube.png";
import ImgITservices from "../Assets/homepage it development.svg";
import ImgCAMservices from "../Assets/homepage cam analytic.svg";
import ImgPCBservices from "../Assets/homepage pcb design.svg";
import ImgCompanyLG from "../Assets/company LG.png";
import ImgCompanyKaist from "../Assets/company kaist.png";
import ImgCompanySamsung from "../Assets/company samsung.png";
import ImgCompanyToshiba from "../Assets/company toshiba.png";
import ButtonUp from "../Components/ButtonUp";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import { useLanguage } from "../Context/LanguageContext";
import { Helmet } from "react-helmet-async";

export default function Homepage() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>PT. Meister Sinergi Indonesia</title>
      </Helmet>
      <div>
        <Navbars textblack invisibleLogo />
        <img
          src={ImgHomepage}
          className="object-cover h-[300px] sm:w-screen sm:h-auto absolute top-0 left-0 -z-10"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="Homepage"
        />
      </div>
      <Container
        className={
          "mt-[250px] md:mt-[325px] lg:mt-[450px] xl:mt-[500px] 2xl:mt-[750px]"
        }
      >
        <div className="mt-12 lg:mt-20 2xl:mt-40 grid gap-8 lg:grid-cols-5 md:grid-cols-3 justify-items-center">
          <div className="bg-white w-[90%] shadow-lg rounded-2xl text-center flex flex-col justify-center items-center py-8 px-4 space-y-8">
            <img
              src={IconIT}
              className="w-1/3"
              alt="Icon"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
            <span className="font-semibold text-2xl">
              {language === "ENG" ? "IT DEVELOPMENT" : "IT 개발"}
            </span>
          </div>
          <div className="bg-white w-[90%] shadow-lg rounded-2xl text-center flex flex-col justify-center items-center py-8 px-4 space-y-8">
            <img
              src={IconPCB}
              className="w-1/3"
              alt="Icon"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
            <span className="font-semibold text-2xl">
              {language === "ENG" ? "PCB DESIGN" : "PCB 디자인"}
            </span>
          </div>
          <div className="bg-white w-[90%] shadow-lg rounded-2xl text-center flex flex-col justify-center items-center py-8 px-4 space-y-8">
            <img
              src={IconCAM}
              className="w-1/3"
              alt="Icon"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
            <span className="font-semibold text-2xl">
              {language === "ENG" ? "CAM ANALYTICS" : "캠 분석"}
            </span>
          </div>
          <div className="bg-white w-[90%] shadow-lg rounded-2xl text-center flex flex-col justify-center items-center py-8 px-4 space-y-8">
            <img
              src={IconQA}
              className="w-1/3"
              alt="Icon"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
            <span className="font-semibold text-2xl">
              {language === "ENG" ? "QA & TESTING" : "QA 및 테스트"}
            </span>
          </div>
          <div className="bg-white w-[90%] shadow-lg rounded-2xl text-center flex flex-col justify-center items-center py-8 px-4 space-y-8">
            <img
              src={IconBEST}
              className="w-1/3"
              alt="Icon"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
            <span className="font-semibold text-2xl">
              {language === "ENG" ? "BEST TEAM" : "최고의 팀"}
            </span>
          </div>
        </div>
      </Container>
      <div className="relative">
        <img
          src={ImgWorldMap}
          className="absolute top-0 left-0 -z-10 w-full"
          alt="World Map"
        />
        <Container className={"my-36 lg:my-48"}>
          <div className="text-center space-y-8 mb-16">
            <h3 className="text-[#005D8D] font-bold text-xl">
              PT. Meister Sinergi Indonesia
            </h3>
            <h1 className="text-[#1e1e1e] font-bold text-4xl md:text-6xl">
              {language === "ENG" ? "Technology for" : "기술"}
            </h1>
            <p className="text-xl w-full md:w-[50%] self-center mx-auto">
              {language === "ENG"
                ? "'Contribute to society by creating future value based on transparent management and technology'"
                : "'투명한 경영과 기술을 바탕으로 미래가치 창출로 사회에 공헌한다'"}
            </p>
          </div>
          <div className="flex flex-col space-y-8 space-x-0 md:flex-row md:space-x-8 md:space-y-0">
            <div className="bg-white shadow-lg text-center rounded-b-xl rounded-e-xl">
              <img
                src={ImgEnvironment}
                alt="Environment"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <p className="py-4 font-bold text-xl">
                {language === "ENG" ? "Environment" : "환경"}
              </p>
            </div>
            <div className="bg-white shadow-lg text-center rounded-b-xl rounded-e-xl">
              <img
                src={ImgSociety}
                alt="Society"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <p className="py-4 font-bold text-xl">
                {language === "ENG" ? "Society" : "사회"}
              </p>
            </div>
            <div className="bg-white shadow-lg text-center rounded-b-xl rounded-e-xl">
              <img
                src={ImgGovernment}
                alt="Government"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <p className="py-4 font-bold text-xl">
                {language === "ENG" ? "Government" : "정부"}
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#f8f8f8] py-20">
        <Container>
          <div className="flex flex-col space-y-8 xl:flex xl:flex-row xl:space-x-8 justify-items-center">
            <div className="bg-white flex flex-col w-full xl:w-1/2 shadow-lg rounded-xl">
              <Carousel>
                <div className="relative">
                  <img
                    src={ImgTeamwork1}
                    alt="Teamwork"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                    className="rounded-t-xl"
                  />
                  <h1 className="text-3xl md:text-7xl text-white font-semibold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    {language === "ENG" ? "Trust" : "신뢰"}
                  </h1>
                </div>
                <div className="relative">
                  <img
                    src={ImgTeamwork2}
                    alt="Teamwork"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                    className="rounded-t-xl"
                  />
                  <h1 className="text-3xl md:text-7xl text-white font-semibold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    {language === "ENG" ? "Service" : "서비스"}
                  </h1>
                </div>
                <div className="relative">
                  <img
                    src={ImgTeamwork3}
                    alt="Teamwork"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                    className="rounded-t-xl"
                  />
                  <h1 className="text-3xl md:text-7xl text-white font-semibold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    {language === "ENG" ? "Professionalism" : "전문성"}
                  </h1>
                </div>
                <div className="relative">
                  <img
                    src={ImgTeamwork4}
                    alt="Teamwork"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                    className="rounded-t-xl"
                  />
                  <h1 className="text-3xl md:text-7xl text-white font-semibold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    {language === "ENG" ? "Future Orientation" : "미래지향성"}
                  </h1>
                </div>
              </Carousel>
              <p className="py-4 px-8 text-justify text-md">
                {language === "ENG"
                  ? "A beacon of reliability and integrity, we are your trusted partner. With a steadfast commitment to excellence, we deliver solutions that stand the test of trust, ensuring peace of mind for every client."
                  : "신뢰성과 성실성의 상징인 우리는 귀하의 신뢰할 수 있는 파트너입니다. 우수성에 대한 확고한 의지로 우리는 신뢰의 시험을 견디는 솔루션을 제공하여 모든 고객에게 마음의 평화를 보장합니다."}
              </p>
            </div>
            <div className="w-full xl:w-1/2">
              <h3 className="text-[#005D8D] font-bold mb-4 mt-8 xl:mb-12 xl:mt-0">
                {"//"} {language === "ENG" ? "TECHNOLOGY INDEX" : "기술 인덱스"}
              </h3>
              <h1 className="font-bold text-5xl mb-8 xl:mb-12">
                {language === "ENG" ? "Be sure." : "확실히하다."} <br />{" "}
                {language === "ENG" ? "We are here!" : "우리는 여기 있습니다!"}
              </h1>
              <p className="text-lg">
                {language === "ENG"
                  ? "Leave all your development needs to us! We meet all customer requirements with a variety of specifications and technology."
                  : "모든 개발에 필요한 것은 우리에게 맡겨주세요! 다양한 사양과 기술력으로 고객의 요구사항을 모두 충족시켜 드립니다."}
              </p>
              <div className="flex flex-row text-center space-x-8 mt-8">
                <div className="flex flex-col justify-items-center">
                  <h1 className="font-extrabold text-4xl text-[#005D8D]">
                    300+
                  </h1>
                  <span className="font-bold">
                    {language === "ENG" ? "Projects Done" : "완료된 프로젝트"}
                  </span>
                </div>
                <div className="flex flex-col justify-items-center">
                  <h1 className="font-extrabold text-4xl text-[#005D8D]">
                    30+
                  </h1>
                  <span className="font-bold">
                    {language === "ENG" ? "Active Clients" : "활성 클라이언트"}
                  </span>
                </div>
                <div className="flex flex-col justify-items-center">
                  <h1 className="font-extrabold text-4xl text-[#005D8D]">5+</h1>
                  <span className="font-bold">
                    {language === "ENG" ? "Team Advisors" : "팀 고문"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container className={"my-20"}>
        <div className="flex flex-row justify-between items-start space-x-8 mb-8">
          <div>
            <h3 className="text-[#005D8D] font-bold mb-8">
              {"//"}
              {language === "ENG" ? "OUR SERVICE" : "우리의 서비스"}
            </h3>
            <h1 className="text-4xl xl:text-5xl font-bold">
              {language === "ENG"
                ? "We offer a wide variety of IT services"
                : "다양한 IT 서비스를 제공하고 있습니다"}
            </h1>
          </div>
          <img
            src={ImgCube}
            className="w-[25%]"
            alt="Cube"
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
        </div>
        <div className="flex flex-col space-x-0 space-y-8 xl:flex xl:flex-row xl:space-x-8 xl:space-y-0 justify-center">
          <div className="flex flex-col shadow-xl">
            <div className="flex flex-row justify-between items-center">
              <h2 className="font-bold ml-8 text-2xl flex-grow">
                {language === "ENG" ? "IT Development" : "IT 개발"}
              </h2>
              <div className="bg-gradient-to-tl from-[#005D8D] from-50% to-[#BFD9E7] rounded-bl-full pb-8 pl-8 pt-4 pr-4">
                <img
                  src={ImgITservices}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="IT services"
                />
              </div>
            </div>
            <p className="px-8 mt-8">
              {language === "ENG"
                ? "We help businesses elevate their value through custom software development, product design, QA and consultancy."
                : "우리는 맞춤형 소프트웨어 개발, 제품 디자인, QA 및 컨설팅을 통해 기업의 가치를 높일 수 있도록 돕습니다."}
            </p>
            <h3 className="px-8 mt-8 text-[#005D8D] font-bold">
              {language === "ENG" ? "INTERNET OF THINGS" : "사물인터넷"}
            </h3>
            <h3 className="px-8 mt-4 text-[#005D8D] font-bold">
              {language === "ENG" ? "WEB/APP DEVELOPMENT" : "웹/앱 개발"}
            </h3>
            <h3 className="px-8 my-4 text-[#005D8D] font-bold">
              {language === "ENG" ? "MOBILE APP DEVELOPMENT" : "모바일 앱 개발"}
            </h3>
          </div>
          <div className="flex flex-col shadow-xl">
            <div className="flex flex-row justify-between items-center">
              <h2 className="font-bold ml-8 text-2xl flex-grow">
                {language === "ENG" ? "CAM Analytics" : "캠 분석"}
              </h2>
              <div className="bg-gradient-to-tl from-[#005D8D] from-50% to-[#BFD9E7] rounded-bl-full pb-8 pl-8 pt-4 pr-4">
                <img
                  src={ImgCAMservices}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="CAM services"
                />
              </div>
            </div>
            <p className="px-8 mt-8">
              {language === "ENG"
                ? "We help businesses elevate their value through custom software development, product design, QA and consultancy."
                : "우리는 맞춤형 소프트웨어 개발, 제품 디자인, QA 및 컨설팅을 통해 기업의 가치를 높일 수 있도록 돕습니다."}
            </p>
            <h3 className="px-8 mt-8 text-[#005D8D] font-bold">
              {language === "ENG" ? "AUTOCAM" : "오토캠"}
            </h3>
            <h3 className="px-8 mt-4 text-[#005D8D] font-bold">
              {language === "ENG" ? "NETLIST REVIEW" : "넷리스트 검토"}
            </h3>
            <h3 className="px-8 my-4 text-[#005D8D] font-bold">
              {language === "ENG" ? "GERBER DATA CREATION" : "거버 데이터 생성"}
            </h3>
          </div>
          <div className="flex flex-col shadow-xl">
            <div className="flex flex-row justify-between items-center">
              <h2 className="font-bold ml-8 text-2xl flex-grow">
                {language === "ENG" ? "PCB Design" : "PCB 설계"}
              </h2>
              <div className="bg-gradient-to-tl from-[#005D8D] from-50% to-[#BFD9E7] rounded-bl-full pb-8 pl-8 pt-4 pr-4">
                <img
                  src={ImgPCBservices}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="PCB services"
                />
              </div>
            </div>
            <p className="px-8 mt-8">
              {language === "ENG"
                ? "We help businesses elevate their value through custom software development, product design, QA and consultancy."
                : "우리는 맞춤형 소프트웨어 개발, 제품 디자인, QA 및 컨설팅을 통해 기업의 가치를 높일 수 있도록 돕습니다."}
            </p>
            <h3 className="px-8 mt-8 text-[#005D8D] font-bold">
              {language === "ENG" ? "MENTOR PADS" : "멘토 패드"}
            </h3>
            <h3 className="px-8 mt-4 text-[#005D8D] font-bold">
              {language === "ENG" ? "ZUKEN" : "ZUKEN"}
            </h3>
            <h3 className="px-8 my-4 text-[#005D8D] font-bold">
              {language === "ENG" ? "ORCAD ALLEGRO" : "오르카드 알레그로"}
            </h3>
          </div>
        </div>
      </Container>
      <Container className={"my-8"}>
        <hr className="border border-[#cfcfcf]" />
        <h3 className="font-bold text-center my-12">
          {language === "ENG"
            ? "Trusted by over 39,000 forward-thinking companies"
            : "39,000개가 넘는 미래 지향적인 기업의 신뢰를 받고 있습니다."}
        </h3>
        <div className="grid grid-cols-3 gap-2 xl:flex xl:flex-row items-center justify-center justify-items-center xl:space-x-4 my-12">
          <img
            src={ImgCompanyKaist}
            alt="company"
            className="h-auto"
            width={"150px"}
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
          <img
            src={ImgCompanyToshiba}
            alt="company"
            className="h-auto"
            width={"150px"}
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
          <img
            src={ImgCompanySamsung}
            alt="company"
            className="h-auto"
            width={"150px"}
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
          <img
            src={ImgCompanyLG}
            alt="company"
            className="h-auto"
            width={"150px"}
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
          <img
            src={ImgCompanyKaist}
            alt="company"
            className="h-auto"
            width={"150px"}
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
          <img
            src={ImgCompanyToshiba}
            alt="company"
            className="h-auto"
            width={"150px"}
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
        </div>
        <hr className="border border-[#cfcfcf]" />
      </Container>
      <Container className={"relative my-16"}>
        <img
          src={ImgTeamwork}
          alt="teamwork"
          className="object-cover h-[500px] w-full xl:w-3/4 xl:h-auto mx-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
        <div className="text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <h2 className="text-[#005D8D] font-bold text-xl xl:text-2xl">
            {language === "ENG"
              ? "Your All in One Technology Solution"
              : "올인원 기술 솔루션"}
          </h2>
          <h1 className="font-bold text-white text-2xl xl:text-4xl my-8">
            {language === "ENG"
              ? "LET'S START BUILD WITH US"
              : "우리와 함께 구축을 시작합시다"}
          </h1>
          <h3 className="font-normal text-white text-xl xl:text-3xl mb-8">
            {language === "ENG"
              ? "Come develop with us!"
              : "우리랑 같이 개발을 하십시요!"}
          </h3>
          <a href="/contact-us">
            <button className="bg-[#005D8D] px-8 py-4 text-white hover:bg-opacity-80 transition-all">
              {language === "ENG" ? "CONTACT US" : "문의하기"}
            </button>
          </a>
        </div>
      </Container>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
