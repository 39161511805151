import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import { HelmetProvider } from "react-helmet-async";
import AboutUs from "./Pages/AboutUs";
import OurProducts from "./Pages/OurProducts";
import ContactUs from "./Pages/ContactUs";
import CADAutocam from "./Pages/CADAutocam";
import CADOutsourcing from "./Pages/CADOutsourcing";
import CADPcbDesign from "./Pages/CADPcbDesign";
import BuildPcb from "./Pages/BuildPcb";
import ITWebDevelopment from "./Pages/ITWebDevelopment";
import ITErpDevelopment from "./Pages/ITErpDevelopment";
import ITPlatform from "./Pages/ITPlatform";
import ITApp from "./Pages/ITApp";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-products" element={<OurProducts />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/web-development" element={<ITWebDevelopment />} />
          <Route path="/erp-development" element={<ITErpDevelopment />} />
          <Route path="/platform-development" element={<ITPlatform />} />
          <Route path="/app-development" element={<ITApp />} />
          <Route path="/autocam-v2" element={<CADAutocam />} />
          <Route path="/cam-outsourcing" element={<CADOutsourcing />} />
          <Route path="/pcb-design" element={<CADPcbDesign />} />
          <Route path="/buildup-pcb" element={<BuildPcb />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
