import React from "react";
import Navbars from "../Components/Navbars";
import { Progress } from "@material-tailwind/react";
import ImgAboutUs from "../Assets/about us image.png";
import ImgAboutUsSection from "../Assets/about us image section.png";
import Container from "../Components/Container";
import ButtonUp from "../Components/ButtonUp";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import IconGroup1 from "../Assets/about us group 1.svg";
import IconGroup2 from "../Assets/about us group 2.svg";
import IconGroup3 from "../Assets/about us group 3.svg";
import IconGroup4 from "../Assets/about us group 4.svg";
import IconGroup5 from "../Assets/about us group 5.svg";
import IconGroup6 from "../Assets/about us group 6.svg";
import ImgAboutIt from "../Assets/about us it service.svg";
import ImgAboutCad from "../Assets/about us cad.svg";
import ImgAboutpcb from "../Assets/about us gadgets.svg";
import ImgKaist from "../Assets/company kaist.png";
import ImgToshiba from "../Assets/company toshiba.png";
import ImgSamsung from "../Assets/company samsung.png";
import ImgLG from "../Assets/company LG.png";
import { useLanguage } from "../Context/LanguageContext";
import { Helmet } from "react-helmet-async";

export default function AboutUs() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - About Us"
            : "PT. Meister Sinergi Indonesia - 회사 소개"}
        </title>
      </Helmet>
      <Navbars textblack currentBold={1} />
      <Container className={"flex flex-col lg:flex-row lg:my-20 mb-20"}>
        <img
          src={ImgAboutUs}
          className="w-full lg:w-1/2"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="about us"
        />
        <div className="text-center lg:text-right">
          <h1 className="font-bold text-4xl lg:text-5xl mb-12">
            {language === "ENG" ? "Business Areas" : "사업분야"}
          </h1>
          <h2 className="text-[#005D8D] font-bold text-3xl lg:text-4xl mb-8">
            {language === "ENG" ? "CAD, CAM solutions, " : "CAD, CAM솔루션, "}
            <br />
            {language === "ENG"
              ? "Build-up PCB sample production company"
              : "빌드업 PCB샘플 제작 전문기업"}
          </h2>
          <p className="mb-4">
            {language === "ENG"
              ? "We specialize in cutting-edge CAD and CAM solutions, seamlessly integrated with bespoke PCB sample production. Elevate your designs with precision, efficiency, and a commitment to excellence."
              : "우리는 맞춤형 PCB 샘플 생산과 원활하게 통합되는 최첨단 CAD 및 CAM 솔루션을 전문으로 합니다. 정확성, 효율성, 우수성에 대한 헌신으로 디자인을 향상시키십시오."}
          </p>
          <a href="/our-products">
            <button className="mb-12 font-semibold bg-[#005D8D] text-white px-8 py-4 hover:bg-opacity-80 transition-all">
              {language === "ENG" ? "OUR SERVICES" : "우리의 서비스"}
            </button>
          </a>
          <p className="font-medium">
            {language === "ENG"
              ? "Auto CAM V2, Build Up PCB Probe Card, Load Board, Mother Board, DUT Board, Auto CAM"
              : "자동 CAM V2, 빌드업 PCB 프로브 카드, 로드 보드, 마더보드, DUT 보드, 자동 CAM"}
          </p>
        </div>
      </Container>
      <div className="relative">
        <img
          src={ImgAboutUsSection}
          className="object-cover w-full h-[1300px] lg:h-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="about us"
        />
        <Container
          className={
            "flex flex-col space-y-16 lg:flex-row lg:space-y-0 justify-around w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:-translate-y-1/3"
          }
        >
          <div className="flex flex-col text-white space-y-4">
            <h1 className="text-center text-5xl font-bold mb-12">
              {language === "ENG" ? "About Us" : "회사소개"}
            </h1>
            <div className="flex flex-row space-x-4 items-center">
              <img
                src={IconGroup1}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="about us group"
              />
              <p>
                {language === "ENG"
                  ? "PT. Meister Sinergi Indonesia"
                  : "PT. 마이스터 시네르기 인도네시아"}
              </p>
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <img
                src={IconGroup2}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="about us group"
              />
              <p>{language === "ENG" ? "March 01, 2022" : "2022년 3월 01일"}</p>
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <img
                src={IconGroup3}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="about us group"
              />
              <p>
                {language === "ENG"
                  ? "Jl. Jajaway No.7 Ciumbuleuit, Kec. Cidadap, Kota Bandung, Jawa Barat 40142"
                  : "Jl. Jajaway No.7 Ciumbuleuit, Kec. 시다답, 반둥 시, 서부 자바 40142"}
              </p>
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <img
                src={IconGroup4}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="about us group"
              />
              <p>
                {language === "ENG"
                  ? "30 people (including domestic and overseas)"
                  : "30명 (국내,국외포함)"}
              </p>
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <img
                src={IconGroup5}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="about us group"
              />
              <p>info@meistersinergi.com</p>
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <img
                src={IconGroup6}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="about us group"
              />
              <a href="http://www.meister.co.id" className="hover:underline">
                <span>www.meister.co.id</span>
              </a>
            </div>
          </div>
          <div className="bg-white shadow-xl p-12 w-full lg:w-1/3 rounded-xl">
            <h2 className="text-[#005D8D] font-bold mb-8">
              {"//"} {language === "ENG" ? "TECHNOLOGY INDEX" : "기술 인덱스"}
            </h2>
            <h1 className="font-bold text-2xl lg:text-4xl mb-8">
              {language === "ENG"
                ? "With improvement and innovation technology trends"
                : "개선 및 혁신기술 트렌드와 함께"}
            </h1>
            <p className="mb-8">
              {language === "ENG"
                ? "We hire and build remote, dedicated development teams tailored to your specific needs."
                : "우리는 귀하의 특정 요구 사항에 맞는 원격 전담 개발 팀을 고용하고 구축합니다."}
            </p>
            <p className="mb-2">
              {language === "ENG" ? "IT SERVICES" : "IT 서비스"}
            </p>
            <span className="flex flex-row items-center mb-4">
              <Progress value={70} size="lg" className="mr-2 bg-[#cfcfcf]" />
              70%
            </span>
            <p className="mb-2">
              {language === "ENG" ? "CAD / CAM" : "캐드 / 캠"}
            </p>
            <span className="flex flex-row items-center mb-4">
              <Progress value={90} size="lg" className="mr-2 bg-[#cfcfcf]" />
              90%
            </span>
            <p className="mb-2">
              {language === "ENG" ? "PCB DESIGN" : "PCB 설계"}
            </p>
            <span className="flex flex-row items-center mb-4">
              <Progress value={90} size="lg" className="mr-2 bg-[#cfcfcf]" />
              90%
            </span>
          </div>
        </Container>
      </div>
      <Container className={"my-20"}>
        <h3 className="text-[#005D8D] font-bold mb-8">
          {"//"} {language === "ENG" ? "WHAT WE OFFER" : "우리가 제공하는 것"}
        </h3>
        <h1 className="font-bold text-4xl lg:text-5xl w-[80%] lg:w-3/4 mb-8">
          {language === "ENG"
            ? "Your partner for software innovation"
            : "소프트웨어 혁신을 위한 파트너"}
        </h1>
        <div className="flex flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-8 lg:space-y-0">
          <p className="w-full lg:w-1/2 text-xl">
            {language === "ENG"
              ? "PT. Meister Sinergi Indonesia is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services."
              : "PT. Meister Sinergi Indonesia는 세계 최고의 기업, 중소기업 및 기술 도전자들이 선택하는 파트너입니다. 우리는 맞춤형 소프트웨어 개발, 제품 디자인, QA 및 컨설팅 서비스를 통해 기업의 가치를 높일 수 있도록 돕습니다."}
          </p>
          <p className="w-full lg:w-1/2 text-xl font-semibold italic">
            {language === "ENG"
              ? "We can help to maintain and modernize your IT infrastructure, CAM/CAD Needs, PCB Designs and solve various infrastructure-specific issues a business may face."
              : "우리는 IT 인프라, CAM/CAD 요구 사항, PCB 설계를 유지 관리하고 현대화하고 비즈니스가 직면할 수 있는 다양한 인프라 관련 문제를 해결하는 데 도움을 드릴 수 있습니다."}
          </p>
        </div>
        <hr className="border-black border my-20" />
      </Container>
      <Container>
        <h3 className="text-[#005D8D] font-bold mb-8">
          {"//"} {language === "ENG" ? "LATEST CASE STUDIES" : "최신 사례 연구"}
        </h3>
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-y-0 justify-between">
          <h1 className="text-4xl lg:text-5xl font-bold">
            {language === "ENG"
              ? "Introduce our projects"
              : "우리 프로젝트를 소개해주세요"}
          </h1>
          <div className="flex flex-col">
            <p className="mb-4 text-xl">
              {language === "ENG"
                ? "Our 10 years Experience brings you feel of reassurance for leaving your development needs to us!"
                : "우리의 10년 경험을 통해 귀하의 개발 요구 사항을 우리에게 맡길 수 있다는 확신을 갖게 되었습니다!"}
            </p>
            <p className="mb-4 text-xl">
              {language === "ENG"
                ? "Our 10 years of experience gives you confidence that you can entrust your development needs to us!"
                : "우리의 10년 경험을 통해 귀하의 개발 요구 사항을 우리에게 맡길 수 있다는 확신을 갖게 되었습니다!"}
            </p>
            <a href="/our-products">
              <button className="px-8 py-4 bg-[#005D8D] text-white hover:opacity-75">
                {language === "ENG"
                  ? "VIEW ALL PROJECTS"
                  : "모든 프로젝트 보기"}
              </button>
            </a>
          </div>
        </div>
        <div className="flex flex-col space-y-4 space-x-0 lg:flex-row my-20 lg:space-x-8 lg:space-y-0">
          <div className="relative flex-grow">
            <img
              src={ImgAboutIt}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              className="rounded-xl w-full"
              alt="icon"
            />
            <div className="bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute bottom-0 left-0 right-0">
              <h1 className="font-bold">
                {language === "ENG" ? "IT SERVICES" : "IT 서비스"}
              </h1>
              <p>{language === "ENG" ? "IDEAS/DESIGN" : "아이디어/디자인"}</p>
            </div>
          </div>
          <div className="relative flex-grow">
            <img
              src={ImgAboutCad}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              className="rounded-xl w-full"
              alt="icon"
            />
            <div className="bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute bottom-0 left-0 right-0">
              <h1 className="font-bold">
                {language === "ENG" ? "CAD / CAM" : "캐드 / 캠"}
              </h1>
              <p>{language === "ENG" ? "DESIGN/DEVELOPMENT" : "디자인 개발"}</p>
            </div>
          </div>
          <div className="relative flex-grow">
            <img
              src={ImgAboutpcb}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              className="rounded-xl w-full"
              alt="icon"
            />
            <div className="bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute bottom-0 left-0 right-0">
              <h1 className="font-bold">
                {language === "ENG" ? "PCB" : "PCB"}
              </h1>
              <p>
                {language === "ENG" ? "IDEAS/DEVELOPMENT" : "아이디어/개발"}
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className="bg-[#F8F8F8] py-16 lg:py-20">
        <Container>
          <h1 className="text-center font-bold text-4xl lg:text-5xl">
            {language === "ENG"
              ? "So, why choose us?"
              : "그렇다면 왜 우리를 선택합니까?"}
          </h1>
          <div className="flex flex-col space-y-8 space-x-0 lg:flex-row my-12 lg:my-20 lg:space-x-8 lg:space-y-0 w-full justify-center">
            <div className="grid grid-cols-2 gap-4 lg:gap-8 justify-items-center">
              <div className="flex flex-col justify-center shadow-xl text-center text-white p-4 bg-gradient-to-br from-[#005D8D] from-40% to-[#46c1ff] w-[155px] h-[155px] lg:w-[200px] lg:h-[200px]">
                <h2 className="font-extrabold text-3xl">30+</h2>
                <h3 className="font-bold text-xl">
                  {language === "ENG" ? "Active Clients" : "활성 클라이언트"}
                </h3>
              </div>
              <div className="flex flex-col justify-center shadow-xl text-center text-white p-4 bg-gradient-to-bl from-[#005D8D] from-40% to-[#46c1ff] w-[155px] h-[155px] lg:w-[200px] lg:h-[200px]">
                <h2 className="font-extrabold text-3xl">300+</h2>
                <h3 className="font-bold text-xl">
                  {language === "ENG" ? "Projects Done" : "완료된 프로젝트"}
                </h3>
              </div>
              <div className="flex flex-col justify-center shadow-xl text-center text-white p-4 bg-gradient-to-tr from-[#005D8D] from-40% to-[#46c1ff] w-[155px] h-[155px] lg:w-[200px] lg:h-[200px]">
                <h2 className="font-extrabold text-3xl">5+</h2>
                <h3 className="font-bold text-xl">
                  {language === "ENG" ? "Team Advisors" : "팀 고문"}
                </h3>
              </div>
              <div className="flex flex-col justify-center shadow-xl text-center text-white p-4 bg-gradient-to-tl from-[#005D8D] from-40% to-[#46c1ff] w-[155px] h-[155px] lg:w-[200px] lg:h-[200px]">
                <h2 className="font-extrabold text-3xl">10+</h2>
                <h3 className="font-bold text-xl">
                  {language === "ENG" ? "Glorious Years" : "영광스러운 해"}
                </h3>
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start">
              <p className="text-justify">
                {language === "ENG"
                  ? "Our expert team ensures seamless technology integration for your business, while our precision in PCB design propels your electronic systems into the future. Trust us for innovation, reliability, and a commitment to your success."
                  : "당사의 전문가 팀은 귀하의 비즈니스를 위한 원활한 기술 통합을 보장하는 동시에 PCB 설계의 정밀성을 통해 귀하의 전자 시스템을 미래로 발전시킵니다. 혁신, 신뢰성 및 성공을 위한 헌신을 위해 우리를 믿으십시오."}
              </p>
              <a href="/contact-us">
                <button className="bg-black px-8 py-4 text-white font-semibold my-8 hover:bg-opacity-75 transition-all">
                  {language === "ENG" ? "CONTACT US" : "문의하기"}
                </button>
              </a>
              <p className="font-bold mb-4">
                {language === "ENG" ? "Partner Companies:" : "파트너 회사:"}
              </p>
              <div className="grid grid-cols-4 justify-center items-center gap-4">
                <img
                  src={ImgKaist}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="about us group"
                />
                <img
                  src={ImgToshiba}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="about us group"
                />
                <img
                  src={ImgSamsung}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="about us group"
                />
                <img
                  src={ImgLG}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="about us group"
                />
                <img
                  src={ImgKaist}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="about us group"
                />
                <img
                  src={ImgToshiba}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="about us group"
                />
                <img
                  src={ImgSamsung}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="about us group"
                />
                <img
                  src={ImgLG}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="about us group"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
