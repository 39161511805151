import React from "react";
import Navbars from "../Components/Navbars";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import ButtonUp from "../Components/ButtonUp";
import Container from "../Components/Container";
import IconWebDesign from "../Assets/web design.svg";
import IconWebDevelopment from "../Assets/web development.svg";
import IconWebData from "../Assets/web data analytic.svg";
import IconWebCyber from "../Assets/web cyber security.svg";
import WebBackground from "../Assets/web background 3.webp";
import WebTeamwork from "../Assets/web teamwork.png";
import WebDevelopmentIcon from "../Assets/web development icon.png";
import WebMobile from "../Assets/web mobile.png";
import WebUiux from "../Assets/web uiux.png";
import WebQa from "../Assets/web qa.png";
import WebItConsultation from "../Assets/web it consultancy.png";
import WebDedicatedTeam from "../Assets/web dedicated team.png";
import WebBackground2 from "../Assets/web background 2.svg";
import WebIcep from "../Assets/web icep logo.png";
import WebTopik from "../Assets/web topik.png";
import WebMeister from "../Assets/web meister.png";
import WebUISection from "../Assets/platform ui section.png";
import WebExperience from "../Assets/web experience.svg";
import WebQuickSupport from "../Assets/web quick support.svg";
import WebTeamwork2 from "../Assets/web teamwork 2.png";
import { Helmet } from "react-helmet-async";
import { useLanguage } from "../Context/LanguageContext";

export default function ITWebDevelopment() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - IT Web Development"
            : "PT. Meister Sinergi Indonesia - IT 웹 개발"}
        </title>
      </Helmet>
      <Navbars textblack currentBold={2} />
      <div className="relative">
        <img
          src={WebBackground}
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="Web Background"
          className="object-cover w-full h-[550px] lg:h-auto"
        />
        <Container
          className={
            "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white w-full"
          }
        >
          <h3 className="font-bold text-xl lg:text-2xl mb-4 text-center lg:text-start">
            {"//"}
            {language === "ENG"
              ? " FULL CYCLE SOFTWARE DEVELOPMENT"
              : " 전체 주기 소프트웨어 개발"}
          </h3>
          <h1 className="font-bold text-4xl lg:text-5xl mb-8 text-center lg:text-start">
            {language === "ENG"
              ? "FROM IDEAS TO PRODUCTS"
              : "아이디어부터 제품까지"}
          </h1>
          <p className="text-xl w-full lg:w-1/2 mb-8 text-center lg:text-start">
            {language === "ENG"
              ? "We are 100+ professional software engineers with more than 10+ years of experience in delivering superior products"
              : "우리는 우수한 제품을 제공하는 데 10년 이상의 경험을 가진 100명 이상의 전문 소프트웨어 엔지니어입니다."}
          </p>
          <div className="flex justify-center lg:justify-start">
            <a href="/our-products">
              <button className="bg-[#005D8D] px-6 py-4 font-semibold hover:bg-opacity-75">
                {language === "ENG" ? "LEARN MORE" : "더 알아보기"}
              </button>
            </a>
          </div>
        </Container>
      </div>
      <div className="bg-white py-28">
        <Container>
          <h2 className="text-[#005D8D] text-2xl lg:text-3xl font-bold mb-4">
            {"//"}
            {language === "ENG" ? " WHY CHOOSE US" : " 왜 우리를 선택 했습니까"}
          </h2>
          <h1 className="text-4xl lg:text-5xl font-bold mb-8 leading-tight">
            {language === "ENG" ? "Design the concept" : "컨셉을 디자인하라"}{" "}
            <br />
            {language === "ENG"
              ? "of your business idea now"
              : "지금 당신의 사업 아이디어에 대해"}
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:space-x-8 justify-center">
            <div className="flex flex-col shadow-xl pt-8 justify-between rounded-xl">
              <h2 className="font-bold ml-8 text-2xl">
                {language === "ENG" ? "Product Design" : "제품 디자인"}
              </h2>
              <p className="px-8 mt-4">
                {language === "ENG"
                  ? "Our product design service lets you prototype, test and validate your ideas."
                  : "당사의 제품 디자인 서비스를 통해 아이디어의 프로토타입을 제작하고 테스트하고 검증할 수 있습니다."}
              </p>
              <div className="mt-4 self-end bg-gradient-to-tr from-[#005D8D] from-60% to-[#E0EBE5] rounded-br-xl rounded-tl-[95%] pb-4 pl-8 pt-8 pr-4">
                <img
                  src={IconWebDesign}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Web Icon"
                />
              </div>
            </div>
            <div className="flex flex-col shadow-xl pt-8 justify-between rounded-xl">
              <h2 className="font-bold ml-8 text-2xl">
                {language === "ENG" ? "Development" : "개발"}
              </h2>
              <p className="px-8 mt-4">
                {language === "ENG"
                  ? "Our product design service lets you prototype, test and validate your ideas."
                  : "당사의 제품 디자인 서비스를 통해 아이디어의 프로토타입을 제작하고 테스트하고 검증할 수 있습니다."}
              </p>
              <div className="mt-4 self-end bg-gradient-to-tr from-[#005D8D] from-60% to-[#E0EBE5] rounded-br-xl rounded-tl-[95%] pb-4 pl-8 pt-8 pr-4">
                <img
                  src={IconWebDevelopment}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Web Icon"
                />
              </div>
            </div>
            <div className="flex flex-col shadow-xl pt-8 justify-between rounded-xl">
              <h2 className="font-bold ml-8 text-2xl">
                {language === "ENG" ? "Data Analytic" : "데이터 분석"}
              </h2>
              <p className="px-8 mt-4">
                {language === "ENG"
                  ? "Our product design service lets you prototype, test and validate your ideas."
                  : "당사의 제품 디자인 서비스를 통해 아이디어의 프로토타입을 제작하고 테스트하고 검증할 수 있습니다."}
              </p>
              <div className="mt-4 self-end bg-gradient-to-tr from-[#005D8D] from-60% to-[#E0EBE5] rounded-br-xl rounded-tl-[95%] pb-4 pl-8 pt-8 pr-4">
                <img
                  src={IconWebData}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Web Icon"
                />
              </div>
            </div>
            <div className="flex flex-col shadow-xl pt-8 justify-between rounded-xl">
              <h2 className="font-bold ml-8 text-2xl">
                {language === "ENG" ? "Cyber Security" : "사이버 보안"}
              </h2>
              <p className="px-8 mt-4">
                {language === "ENG"
                  ? "Our product design service lets you prototype, test and validate your ideas."
                  : "당사의 제품 디자인 서비스를 통해 아이디어의 프로토타입을 제작하고 테스트하고 검증할 수 있습니다."}
              </p>
              <div className="mt-4 self-end bg-gradient-to-tr from-[#005D8D] from-60% to-[#E0EBE5] rounded-br-xl rounded-tl-[95%] pb-4 pl-8 pt-8 pr-4">
                <img
                  src={IconWebCyber}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="Web Icon"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#BFD9E7] py-28">
        <div
          className={
            "bg-[#005D8D] mx-auto lg:mx-20 rounded-xl w-[90%] lg:w-auto"
          }
        >
          <Container
            className={
              "relative flex flex-col space-y-8 lg:flex-row justify-around items-center lg:space-x-8 lg:space-y-0 py-16 text-center lg:text-start"
            }
          >
            <div className="text-white">
              <span className="text-5xl border-b-4 border-[#f2c96f] my-8 w-full font-extrabold shadow-lg">
                15+
              </span>
              <h2 className="text-3xl mt-8 mb-4 font-semibold">
                {language === "ENG" ? "Countries Worldwide" : "전세계 국가"}
              </h2>
              <p className="font-normal">
                {language === "ENG"
                  ? "To succeed, every software solution must be deeply integrated into the existing tech environment."
                  : "성공하려면 모든 소프트웨어 솔루션이 기존 기술 환경에 긴밀하게 통합되어야 합니다."}
              </p>
            </div>
            <div className="text-white">
              <span className="text-5xl border-b-4 border-[#f2c96f] my-8 w-full font-extrabold shadow-lg">
                23k
              </span>
              <h2 className="text-3xl mt-8 mb-4 font-semibold">
                {language === "ENG" ? "Happy Customers" : "행복한 고객"}
              </h2>
              <p className="font-normal">
                {language === "ENG"
                  ? "To succeed, every software solution must be deeply integrated into the existing tech environment."
                  : "성공하려면 모든 소프트웨어 솔루션이 기존 기술 환경에 긴밀하게 통합되어야 합니다."}
              </p>
            </div>
            <div>
              <img
                src={WebTeamwork}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="Illustration"
                className="w-[75%] lg:w-full mx-auto"
              />
            </div>
          </Container>
        </div>
      </div>
      <div className="bg-[#F6F2FF] py-14 lg:py-28">
        <Container>
          <h3 className="font-bold text-xl lg:text-2xl mb-4 text-[#005D8D]">
            {"//"} {language === "ENG" ? " OUR SERVICES" : " 우리의 서비스"}
          </h3>
          <h1 className="font-bold text-4xl lg:text-5xl mb-8 leading-tight">
            {language === "ENG"
              ? "We offer a wide variety"
              : "우리는 다양한 것을 제공합니다"}
            <br />
            {language === "ENG" ? "of IT services" : "IT 서비스의"}
          </h1>
          <a href="/our-products">
            <button className="bg-[#005D8D] px-6 py-4 font-semibold text-white hover:bg-opacity-75">
              {language === "ENG" ? "ALL SERVICES" : "모든 서비스"}
            </button>
          </a>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 my-8">
            <div className="flex flex-col space-y-4 justify-between p-8 shadow-xl lg:shadow-none">
              <img
                src={WebDevelopmentIcon}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="Icon"
                className="w-1/4"
              />
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "Web Development" : "웹 개발"}
              </h1>
              <p className="font-normal">
                {language === "ENG"
                  ? "We carry more than just good coding skills. Our experience makes us stand out from other web development."
                  : "우리는 단지 좋은 코딩 기술 그 이상을 가지고 있습니다. 우리의 경험은 우리를 다른 웹 개발보다 돋보이게 만듭니다."}
              </p>
            </div>
            <div className="flex flex-col space-y-4 justify-between p-8 shadow-xl lg:shadow-none">
              <img
                src={WebMobile}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="Icon"
                className="w-1/4"
              />
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "Mobile Development" : "모바일 개발"}
              </h1>
              <p className="font-normal">
                {language === "ENG"
                  ? "Create complex enterprise software, ensure reliable software integration, modernise your legacy system."
                  : "복잡한 엔터프라이즈 소프트웨어를 만들고, 안정적인 소프트웨어 통합을 보장하고, 레거시 시스템을 현대화하세요."}
              </p>
            </div>
            <div className="flex flex-col space-y-4 justify-between p-8 shadow-xl lg:shadow-none">
              <img
                src={WebUiux}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="Icon"
                className="w-1/4"
              />
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "UI / UX Design" : "UI/UX 디자인"}
              </h1>
              <p className="font-normal">
                {language === "ENG"
                  ? "Build the product you need on time with an experienced team that uses a clear and effective design process."
                  : "명확하고 효과적인 설계 프로세스를 사용하는 숙련된 팀과 함께 필요한 제품을 적시에 제작하세요."}
              </p>
            </div>
            <div className="flex flex-col space-y-4 justify-between p-8 shadow-xl lg:shadow-none">
              <img
                src={WebQa}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="Icon"
                className="w-1/4"
              />
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "QA & Testing" : "QA 및 테스트"}
              </h1>
              <p className="font-normal">
                {language === "ENG"
                  ? "Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software."
                  : "당사의 전문가에게 문의하여 소프트웨어에 대한 포괄적인 다단계 테스트 및 감사를 수행하십시오."}
              </p>
            </div>
            <div className="flex flex-col space-y-4 justify-between p-8 shadow-xl lg:shadow-none">
              <img
                src={WebItConsultation}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="Icon"
                className="w-1/4"
              />
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "IT Consultancy" : "IT 컨설팅"}
              </h1>
              <p className="font-normal">
                {language === "ENG"
                  ? "Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios."
                  : "워크플로우 문제점을 제거하고, 새로운 기술을 구현하고, 앱 포트폴리오를 통합하는 데 있어 최고의 인재를 신뢰하십시오."}
              </p>
            </div>
            <div className="flex flex-col space-y-4 justify-between p-8 shadow-xl lg:shadow-none">
              <img
                src={WebDedicatedTeam}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="Icon"
                className="w-1/4"
              />
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "Dedicated Team" : "전담팀"}
              </h1>
              <p className="font-normal">
                {language === "ENG"
                  ? "Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating."
                  : "지난 10년 동안 우리 고객들은 Intellectsoft의 구축, 동기 부여 프로세스를 활용하여 성공을 거두었습니다."}
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="relative">
        <img
          src={WebBackground2}
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="Icon"
          className="object-cover w-full h-[1200px] lg:h-auto"
        />
        <div className="flex flex-col items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <h3 className="text-center font-bold text-2xl mb-4 text-[#005D8D]">
            {"//"} {language === "ENG" ? " LATEST PROJECTS" : " 최신 프로젝트"}
          </h3>
          <h1 className="text-center font-bold text-4xl lg:text-5xl mb-8 leading-tight">
            {language === "ENG"
              ? "Introduce our projects"
              : "우리 프로젝트를 소개해주세요"}
          </h1>
          <a href="/our-products">
            <button className="bg-[#005D8D] px-6 py-4 font-semibold text-white">
              {language === "ENG" ? "VIEW ALL PROJECTS" : "모든 프로젝트 보기"}
            </button>
          </a>
          <div className="flex flex-col space-y-28 space-x-0 lg:flex-row items-center my-20 lg:space-y-0 lg:space-x-8">
            <div className="relative flex-grow w-[250px]">
              <img
                src={WebIcep}
                className="rounded-xl w-1/2 mx-auto"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="icon"
              />
              <div className="shadow-xl bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-[58%]">
                <h1 className="font-bold">
                  {language === "ENG" ? "ICEP Website" : "ICEP 웹사이트"}
                </h1>
                <p>
                  {language === "ENG" ? "DESIGN/DEVELOPMENT" : "디자인 개발"}
                </p>
              </div>
            </div>
            <div className="relative flex-grow w-[250px]">
              <img
                src={WebTopik}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                className="rounded-xl w-full"
                alt="icon"
              />
              <div className="shadow-xl bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-[58%]">
                <h1 className="font-bold">
                  {language === "ENG" ? "TOPIK III Course" : "TOPIK III 코스"}
                </h1>
                <p>
                  {language === "ENG" ? "DESIGN/DEVELOPMENT" : "디자인 개발"}
                </p>
              </div>
            </div>
            <div className="relative flex-grow w-[250px]">
              <img
                src={WebMeister}
                className="rounded-xl w-1/2 mx-auto"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="icon"
              />
              <div className="shadow-xl bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-[58%]">
                <h1 className="font-bold">
                  {language === "ENG" ? "Meister Website" : "마이스터 홈페이지"}
                </h1>
                <p>
                  {language === "ENG" ? "IDEAS/DEVELOPMENT" : "아이디어/개발"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#f8f8f8] py-28">
        <Container
          className={
            "flex flex-col space-y-8 space-x-0 lg:flex-row items-center lg:space-x-4 lg:space-y-0"
          }
        >
          <div className="w-full">
            <h3 className="font-bold text-2xl mb-4 text-[#005D8D]">
              {"//"}{" "}
              {language === "ENG"
                ? " WE CARRY MORE"
                : " 우리는 더 많은 것을 가지고 다닙니다"}
              <br />
              {language === "ENG"
                ? "THAN JUST GOOD CODING SKILLS"
                : "단지 좋은 코딩 기술보다"}
            </h3>
            <h1 className="font-bold text-4xl lg:text-5xl mb-8 leading-tight">
              {language === "ENG"
                ? "Let's build your website!"
                : "귀하의 웹사이트를 구축해 봅시다!"}
            </h1>
            <a href="/contact-us">
              <button className="bg-[#005D8D] px-6 py-4 font-semibold text-white hover:bg-opacity-75">
                {language === "ENG" ? "CONTACT US" : "문의하기"}
              </button>
            </a>
          </div>
          <div>
            <img
              src={WebUISection}
              className="w-full"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              alt="section"
            />
          </div>
        </Container>
      </div>
      <div className="bg-white py-28">
        <Container>
          <div className="flex flex-col space-y-12 space-x-0 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div className="flex flex-col w-full">
              <div className="w-full">
                <h3 className="font-bold text-2xl mb-4 text-[#005D8D]">
                  {"//"} {language === "ENG" ? " ABOUT COMPANY" : " 회사 소개"}
                </h3>
                <h1 className="font-bold text-4xl lg:text-5xl mb-8 leading-tight">
                  {language === "ENG"
                    ? "Your partner for software innovation"
                    : "소프트웨어 혁신을 위한 파트너"}
                </h1>
                <p className="mb-8">
                  {language === "ENG"
                    ? "PT. Meister Sinergi Indonesia is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services."
                    : "PT. Meister Sinergi Indonesia는 세계 최고의 기업, 중소기업 및 기술 도전자들이 선택하는 파트너입니다. 우리는 맞춤형 소프트웨어 개발, 제품 디자인, QA 및 컨설팅 서비스를 통해 기업의 가치를 높일 수 있도록 돕습니다."}
                </p>
                <a href="/about-us">
                  <button className="bg-[#005D8D] px-6 py-4 font-semibold text-white hover:bg-opacity-75">
                    {language === "ENG"
                      ? "LEARN MORE ABOUT US"
                      : "우리에 대해 더 알아보기"}
                  </button>
                </a>
              </div>
            </div>
            <div className="flex flex-col w-full space-y-8">
              <div className="flex flex-row space-x-8">
                <div className="flex flex-col lg:text-start text-center items-center lg:items-start justify-start">
                  <img
                    src={WebExperience}
                    className="w-auto h-[50px] mb-4"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                    alt="teamwork"
                  />
                  <h4 className="font-bold text-xl mb-2">
                    {language === "ENG" ? "Experience" : "경험"}
                  </h4>
                  <p>
                    {language === "ENG"
                      ? "Our great team of more than 1400 software experts."
                      : "1400명이 넘는 소프트웨어 전문가로 구성된 훌륭한 팀입니다."}
                  </p>
                </div>
                <div className="flex flex-col lg:text-start text-center items-center lg:items-start justify-start">
                  <img
                    src={WebQuickSupport}
                    className="w-auto h-[50px] mb-4"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                    alt="teamwork"
                  />
                  <h4 className="font-bold text-xl mb-2">
                    {language === "ENG" ? "Quick Support" : "빠른 지원"}
                  </h4>
                  <p>
                    {language === "ENG"
                      ? "We’ll help you test bold new ideas while sharing your own"
                      : "여러분의 아이디어를 공유하면서 대담하고 새로운 아이디어를 테스트할 수 있도록 도와드리겠습니다."}
                  </p>
                </div>
              </div>
              <div>
                <img
                  src={WebTeamwork2}
                  className="w-full"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                  alt="teamwork"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
