import React from "react";
import Container from "./Container";
import LogoMeister from "../Assets/logo2.png";
import LogoTwitter from "../Assets/logo Twitter Circled.svg";
import LogoFacebook from "../Assets/logo Facebook Circled.svg";
import LogoInstagram from "../Assets/logo Instagram Circle.svg";
import LogoLinkedIn from "../Assets/logo LinkedIn Circled.svg";
import { useLanguage } from "../Context/LanguageContext";

export default function Footer() {
  const { language } = useLanguage();

  return (
    <div className="bg-[#1e1e1e] pt-28 pb-8">
      <Container className={"text-white"}>
        <div className="flex flex-row justify-around space-x-8">
          <div className="flex flex-col w-1/2 lg:w-[20%]">
            <a className="hover:opacity-50" href="/">
              <img
                src={LogoMeister}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="Meister logo"
              />
            </a>
            <p className="mt-8">
              {language === "ENG"
                ? "Find us on social media"
                : "소셜 미디어에서 우리를 찾아보세요"}
            </p>
            <div className="flex flex-row space-x-4 justify-items-center my-4">
              <img
                src={LogoTwitter}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="logo"
                className="w-[32px] md:w-[64px] h-auto"
              />
              <img
                src={LogoFacebook}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="logo"
                className="w-[32px] md:w-[64px] h-auto"
              />
              <img
                src={LogoInstagram}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="logo"
                className="w-[32px] md:w-[64px] h-auto"
              />
              <img
                src={LogoLinkedIn}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="logo"
                className="w-[32px] md:w-[64px] h-auto"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-20">
            <div className="flex flex-col">
              <h3 className="font-semibold mb-6 text-2xl">
                {language === "ENG" ? "Services" : "서비스"}
              </h3>
              <a href={"/web-development"} className="mb-4 hover:text-gray-500">
                {language === "ENG" ? "IT Development" : "IT 개발"}
              </a>
              <a href={"/autocam-v2"} className="mb-4 hover:text-gray-500">
                {language === "ENG" ? "CAD / CAM" : "캐드/캠"}
              </a>
              <a href={"/buildup-pcb"} className="mb-4 hover:text-gray-500">
                {language === "ENG" ? "PCB Design" : "PCB 설계"}
              </a>
            </div>
            <div className="flex flex-col">
              <h3 className="font-semibold mb-6 text-2xl">
                {language === "ENG" ? "Company" : "회사"}
              </h3>
              <a href={"/our-products"} className="mb-4 hover:text-gray-500">
                {language === "ENG" ? "Our Products" : "우리의 제품"}
              </a>
              <a href={"/about-us"} className="mb-4 hover:text-gray-500">
                {language === "ENG" ? "About Us" : "회사 소개"}
              </a>
            </div>
            <div className="flex flex-col">
              <h3 className="font-semibold mb-6 text-2xl">
                {language === "ENG" ? "Contact" : "연락하다"}
              </h3>
              <a href={"/contact-us"} className="mb-4 hover:text-gray-500">
                {language === "ENG" ? "Contact Us" : "문의하기"}
              </a>
              <a href={"/"} className="mb-4 hover:text-gray-500">
                {language === "ENG" ? "E-mail" : "이메일"}
              </a>
            </div>
          </div>
        </div>
        <hr className="my-8" />
        <h2 className="text-center">
          {language === "ENG"
            ? "@ 2024 PT. Meister Sinergi Indonesia. All rights reserved"
            : "@ 2024 태평양 표준시. 마이스터 시네르기 인도네시아. 판권 소유"}
        </h2>
      </Container>
    </div>
  );
}
