import React from "react";
import Navbars from "../Components/Navbars";
import ImgBackgroundCamOutsourcing from "../Assets/cam outsourcing background.png";
import Container from "../Components/Container";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import ButtonUp from "../Components/ButtonUp";
import ImgIllustration from "../Assets/cam outsourcing illustration.png";
import IconTestimonialCompany from "../Assets/contact us logo testimonial.png";
import IconGlobalMap from "../Assets/contact us global map.png";
import ImgVid from "../Assets/cam outsourcing img vid.png";
import { useLanguage } from "../Context/LanguageContext";
import { Helmet } from "react-helmet-async";

export default function CADOutsourcing() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - CAD Outsourcing"
            : "PT. Meister Sinergi Indonesia - CAD 아웃소싱"}
        </title>
      </Helmet>
      <Navbars textblack currentBold={3} />
      <div className="background-container">
        <img
          src={ImgBackgroundCamOutsourcing}
          alt="background"
          className="w-full"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
      </div>
      <div className="my-12 lg:my-28">
        <Container
          className={
            "flex flex-col-reverse space-y-8 space-x-0 lg:flex-row justify-between lg:space-x-12 lg:space-y-0"
          }
        >
          <img
            src={ImgIllustration}
            alt="teamwork"
            className="w-3/4 lg:w-1/4 h-full lg:self-end self-center"
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
          <div className="flex-grow">
            <h1 className="font-bold text-4xl lg:text-5xl text-center lg:text-right mb-8">
              {language === "ENG"
                ? "CAM Outsourcing Service"
                : "CAM 아웃소싱 서비스"}
            </h1>
            <h2 className="font-bold text-2xl lg:text-3xl text-center lg:text-right mb-12 text-[#005D8D]">
              {language === "ENG"
                ? "CAM processing volume per day by experienced person"
                : "경력자별 1일 CAM 처리량"}
            </h2>
            <div className="flex flex-col space-y-1 justify-center items-center">
              <h3 className="bg-[#005D8D] font-semibold text-white w-full text-center py-2 rounded-lg">
                {language === "ENG"
                  ? "Written based on the 4th floor"
                  : "4층 기준으로 작성"}
              </h3>
              <div className="flex flex-row space-x-1 w-full text-center font-medium items-stretch">
                <p className="flex justify-center items-center bg-[#BFD9E7] py-2 w-full rounded-lg">
                  {language === "ENG"
                    ? "Daily CAM service throughput"
                    : "하루 CAM용역 처리량"}
                </p>
                <p className="flex justify-center items-center bg-[#EAF2EE] py-2 w-full rounded-lg">
                  {language === "ENG" ? "Career" : "경력"}
                </p>
                <p className="flex justify-center items-center bg-[#F5F9F7] py-2 w-full rounded-lg">
                  {language === "ENG" ? "Number of people" : "인원수"}
                </p>
              </div>
              <div className="flex flex-row space-x-1 w-full text-center font-medium">
                <p className="flex justify-center items-center bg-[#BFD9E7] py-2 w-full rounded-lg">
                  {language === "ENG" ? "Less than 5 cases" : "5건 미만"}
                </p>
                <p className="flex justify-center items-center bg-[#EAF2EE] py-2 w-full rounded-lg">
                  {language === "ENG" ? "3rd year" : "3년차"}
                </p>
                <p className="flex justify-center items-center bg-[#F5F9F7] py-2 w-full rounded-lg">
                  {language === "ENG" ? "1 person" : "1명"}
                </p>
              </div>
              <div className="flex flex-row space-x-1 w-full text-center font-medium">
                <p className="flex justify-center items-center bg-[#BFD9E7] py-2 w-full rounded-lg">
                  {language === "ENG" ? "Less than 10 cases" : "10건 미만"}
                </p>
                <p className="flex justify-center items-center bg-[#EAF2EE] py-2 w-full rounded-lg">
                  {language === "ENG" ? "5th year" : "5년차"}
                </p>
                <p className="flex justify-center items-center bg-[#F5F9F7] py-2 w-full rounded-lg">
                  {language === "ENG" ? "1 person" : "1명"}
                </p>
              </div>
              <div className="flex flex-row space-x-1 w-full text-center font-medium">
                <p className="flex justify-center items-center bg-[#BFD9E7] py-2 w-full rounded-lg">
                  {language === "ENG" ? "Less than 20 cases" : "20건 미만"}
                </p>
                <p className="flex justify-center items-center bg-[#EAF2EE] py-2 w-full rounded-lg">
                  {language === "ENG"
                    ? "2 people with 5 years or more"
                    : "5년차 이상 2인"}
                </p>
                <p className="flex justify-center items-center bg-[#F5F9F7] py-2 w-full rounded-lg">
                  {language === "ENG" ? "2 people" : "2명"}
                </p>
              </div>
            </div>
            <div className="flex flex-col space-y-1 justify-center items-center my-8">
              <h3 className="bg-[#005D8D] font-semibold text-white w-full text-center py-2 rounded-lg">
                {language === "ENG"
                  ? "Double-sided, 4th floor, 6th floor or higher"
                  : "양면, 4층, 6층 이상"}
              </h3>
              <div className="flex flex-row space-x-1 w-full text-center font-medium">
                <p className="flex justify-center items-center bg-[#BFD9E7] p-2 w-full rounded-lg">
                  {language === "ENG" ? "Number of PCB layers" : "PCB층수"}
                </p>
                <p className="flex justify-center items-center bg-[#E4EDE8] py-2 w-full rounded-lg">
                  {language === "ENG" ? "both sides" : "양면"}
                </p>
                <p className="flex justify-center items-center bg-[#EAF2EE] py-2 w-full rounded-lg">
                  {language === "ENG" ? "4th floor" : "4층"}
                </p>
                <p className="flex justify-center items-center bg-[#F5F9F7] py-2 w-full rounded-lg">
                  {language === "ENG" ? "6th floor or higher" : "6층 이상"}
                </p>
              </div>
              <div className="flex flex-row space-x-1 w-full text-center font-medium">
                <p className="flex justify-center items-center bg-[#BFD9E7] p-2 w-full rounded-lg">
                  {language === "ENG" ? "less than 3 years" : "3년 미만"}
                </p>
                <p className="flex justify-center items-center bg-[#E4EDE8] py-2 w-full rounded-lg">
                  5
                </p>
                <p className="flex justify-center items-center bg-[#EAF2EE] py-2 w-full rounded-lg">
                  3
                </p>
                <p className="flex justify-center items-center bg-[#F5F9F7] py-2 w-full rounded-lg">
                  2
                </p>
              </div>
              <div className="flex flex-row space-x-1 w-full text-center font-medium">
                <p className="flex justify-center items-center bg-[#BFD9E7] p-2 w-full rounded-lg">
                  {language === "ENG" ? "3-5 years" : "3~5년"}
                </p>
                <p className="flex justify-center items-center bg-[#E4EDE8] py-2 w-full rounded-lg">
                  10
                </p>
                <p className="flex justify-center items-center bg-[#EAF2EE] py-2 w-full rounded-lg">
                  5
                </p>
                <p className="flex justify-center items-center bg-[#F5F9F7] py-2 w-full rounded-lg">
                  3
                </p>
              </div>
              <div className="flex flex-row space-x-1 w-full text-center font-medium">
                <p className="flex justify-center items-center bg-[#BFD9E7] p-2 w-full rounded-lg">
                  {language === "ENG" ? "More than 5 years" : "5년이상"}
                </p>
                <p className="flex justify-center items-center bg-[#E4EDE8] py-2 w-full rounded-lg">
                  20
                </p>
                <p className="flex justify-center items-center bg-[#EAF2EE] py-2 w-full rounded-lg">
                  10
                </p>
                <p className="flex justify-center items-center bg-[#F5F9F7] py-2 w-full rounded-lg">
                  5
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="relative my-12 lg:my-20">
        <img
          src={IconGlobalMap}
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="global map contact us"
          className="object-cover w-full h-[1000px] lg:h-auto"
        />
        <div className="text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] lg:w-auto">
          <h3 className="text-[#005D8D] font-bold text-2xl mb-8">
            {"//"} {language === "ENG" ? " OUR CLIENTS" : " 우리의 클라이언트"}
          </h3>
          <h1 className="font-bold text-4xl lg:text-5xl">
            {language === "ENG"
              ? "We are trusted 15+ countries worldwide"
              : "우리는 전 세계 15개 이상의 국가에서 신뢰를 받고 있습니다."}
          </h1>
          <div className="bg-white shadow-xl py-8 px-8 my-12 rounded-xl">
            <p>
              {language === "ENG"
                ? '"Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."'
                : '"인내. 무한한 인내심. 지름길이 없습니다. 아주 잘 생각되고 명확한 의사소통. 명확한 이정표, 마감일 및 빠른 작업. 클라이언트가 부주의하더라도. 가장 중요한 부분은...항상 훌륭하고 독창적인 아이디어로 문제를 해결하는 것입니다!."'}
            </p>
            <img
              src={IconTestimonialCompany}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              alt="testimonial contact us"
              className="mx-auto w-[40%] lg:w-[10%] my-8"
            />
            <p className="text-[#005D8D] font-bold">
              {language === "ENG" ? "John Doe" : "존 도우"}
            </p>
            <p className="text-[#005D8D] font-normal">
              {language === "ENG" ? "Manager, SoftTech" : "소프트테크 매니저"}
            </p>
          </div>
        </div>
      </div>
      <div className="my-12 lg:my-28">
        <Container>
          <div className="bg-[#005D8D] rounded-xl px-12 py-24 text-white text-center">
            <span className="text-5xl border-b-4 border-[#f2c96f] my-8 w-full font-extrabold shadow-lg">
              10+
            </span>
            <h2 className="text-2xl mt-8 mb-4 font-bold">
              {language === "ENG"
                ? "Affordable outsourcing"
                : "저렴한 아웃소싱"}
            </h2>
            <p className="font-normal">
              {language === "ENG"
                ? "More added value while maintaining quality of the products."
                : "제품의 품질을 유지하면서 더 많은 부가가치를 창출합니다."}
            </p>
          </div>
        </Container>
      </div>
      <div className="bg-[#f8f8f8] py-12 lg:py-28">
        <Container
          className={
            "flex flex-col-reverse space-y-8 lg:flex-row justify-center lg:space-y-0 text-center lg:text-start"
          }
        >
          <div className="w-full lg:w-1/2">
            <h3 className="text-2xl lg:text-3xl font-bold text-[#005D8D] mb-8">
              {language === "ENG"
                ? "YOUR ALL IN ONE TECHNOLOGY SOLUTION"
                : "올인원 기술 솔루션"}
            </h3>
            <h1 className="text-4xl lg:text-5xl font-bold mb-8">
              {language === "ENG"
                ? "Let’s start build with us"
                : "우리와 함께 구축을 시작합시다"}
            </h1>
            <a href="/contact-us">
              <button className="bg-[#1e1e1e] px-4 py-2 text-white hover:bg-opacity-75">
                {language === "ENG" ? "CONTACT US" : "문의하기"}
              </button>
            </a>
          </div>
          <div className="w-full lg:w-1/2 relative">
            <img
              src={ImgVid}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              alt="Vid"
              className=""
            />
          </div>
        </Container>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
