import React from "react";
import Navbars from "../Components/Navbars";
import Container from "../Components/Container";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import ButtonUp from "../Components/ButtonUp";
import ImgHeadline from "../Assets/our products headline.svg";
import ImgPCBDesignRight from "../Assets/pcb design img right.png";
import ImgPCBDesignSection from "../Assets/pcb design img section.svg";
import ImgPCBDesignAllegro from "../Assets/pcb design allegro.png";
import ImgPCBDesignAutocad from "../Assets/pcb design autocad.png";
import ImgPCBDesignPcad from "../Assets/pcb design pcad.png";
import ImgPCBDesignPads from "../Assets/pcb design pads.png";
import { useLanguage } from "../Context/LanguageContext";
import { Helmet } from "react-helmet-async";

export default function CADPcbDesign() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - CAD PCB Design"
            : "PT. Meister Sinergi Indonesia - CAD PCB 설계"}
        </title>
      </Helmet>
      <div className="relative">
        <Navbars currentBold={3} />
        <img
          src={ImgHeadline}
          className="object-cover w-full h-[275px] lg:h-auto absolute top-0 left-0 -z-10"
          alt="headline"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
        <Container>
          <h1 className="text-white font-semibold text-4xl lg:text-5xl mt-12 lg:mt-20 text-center lg:text-start">
            {language === "ENG"
              ? "Power Design Experience"
              : "전력 설계 경험전력 설계 경험"}
          </h1>
        </Container>
      </div>
      <div className="my-20">
        <Container
          className={
            "mt-16 lg:mt-44 flex flex-col-reverse space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0"
          }
        >
          <div>
            <span className="bg-[#005D8D] font-semibold text-xl text-white px-4 py-2 rounded-2xl">
              {language === "ENG" ? "2013 ~ Present" : "2013 ~ 현재"}
            </span>
            <ul className="font-medium ml-16 py-2 border-l-4 border-[#BFD9E7]">
              <li className="my-4 custom-list-item">
                {language === "ENG"
                  ? "OBCM (3.6Kw, 7.7Kw, 11Kw, 22Kw) 2 or more models per year, HONDA, MOBIS, GM, etc."
                  : "OBCM (3.6Kw, 7.7Kw, 11Kw, 22Kw ) 매년 2모델 이상, HONDA, MOBIS, GM 등"}
              </li>
              <li className="my-4 custom-list-item">
                {language === "ENG"
                  ? "INVERTER (120KW,160KW) multiple models"
                  : "INVERTER (120KW,160KW) 다수 모델"}
              </li>
            </ul>
            <span className="bg-[#005D8D] font-semibold text-xl text-white px-4 py-2 rounded-2xl">
              {language === "ENG" ? "2019 ~ Present" : "2019 ~ 현재"}
            </span>
            <ul className="font-medium ml-16 py-2 border-l-4 border-[#BFD9E7]">
              <li className="my-4 custom-list-item">
                {language === "ENG"
                  ? "ESS (5kW, 7.2KW, 11KW…) more than 3 models every year"
                  : "ESS (5kW, 7.2KW, 11KW…) 매년 3개모델 이상"}
              </li>
            </ul>
            <span className="bg-[#005D8D] font-semibold text-xl text-white px-4 py-2 rounded-2xl">
              2015 ~ 2020
            </span>
            <ul className="font-medium ml-16 py-2 border-l-4 border-[#BFD9E7]">
              <li className="my-4 custom-list-item">
                {language === "ENG"
                  ? "ESS (5KW, 10KW …) 10 or more"
                  : "ESS (5KW,10KW …) 10개 이상"}
              </li>
            </ul>
            <span className="bg-[#005D8D] font-semibold text-xl text-white px-4 py-2 rounded-2xl">
              2005~2016
            </span>
            <ul className="font-medium ml-16 py-2 border-l-4 border-[#BFD9E7]">
              <li className="my-4 custom-list-item">
                {language === "ENG"
                  ? "TV Power, LED lighting, ESS, electronics sector, wireless charging board"
                  : "TV Power, LED 조명, ESS, 전장부문, 무선충전 보드"}
              </li>
            </ul>
            <span className="bg-[#005D8D] font-semibold text-xl text-white px-4 py-2 rounded-2xl">
              2000 ~ 2010
            </span>
            <ul className="font-medium ml-16 py-2 border-l-4 border-[#BFD9E7] border-b-4">
              <li className="my-4 custom-list-item">
                {language === "ENG"
                  ? "CRT TV, CRT MONITOR, LCD PDP power design"
                  : "CRT TV, CRT MONITOR, LCD PDP파워 설계"}
              </li>
              <li className="my-4 custom-list-item">
                {language === "ENG"
                  ? "Dedicated to designing more than 90% of LG Electronics TV power from 2003 to 2010"
                  : "2003 ~ 2010 기간동안 엘지전자 TV 파워 90% 이상 설계전담"}
              </li>
            </ul>
          </div>
          <img
            src={ImgPCBDesignRight}
            className="w-full lg:w-1/2"
            alt="headline"
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
        </Container>
      </div>
      <div className={"my-20 relative"}>
        <img
          src={ImgPCBDesignSection}
          className="object-cover w-full h-[1600px] lg:h-auto"
          alt="headline"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
        <div className="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Container>
            <h1 className="font-bold text-4xl lg:text-5xl mb-8">
              {language === "ENG"
                ? "Semiconductor-related PCB Design"
                : "반도체관련 PCB Design"}
            </h1>
            <h2 className="font-bold text-2xl lg:text-3xl text-[#005D8D] mb-8">
              {"//"}
              {language === "ENG" ? " HIGH TECHNOLOGY PCB" : " 첨단 기술 PCB"}
            </h2>
            <p className="font-normal text-xl">
              {language === "ENG"
                ? "Elevate your electronic innovations with our High Technology PCBs. Engineered for precision and performance, our advanced circuit boards push the boundaries of technology. From complex designs to cutting-edge materials, we provide solutions that meet the demands of high-tech applications, ensuring reliability and efficiency in every electronic endeavor."
                : "당사의 첨단 기술 PCB로 전자 혁신을 향상시키십시오. 정밀도와 성능을 위해 설계된 당사의 고급 회로 기판은 기술의 한계를 뛰어넘습니다. 복잡한 디자인부터 최첨단 소재까지, 당사는 첨단 기술 애플리케이션의 요구 사항을 충족하는 솔루션을 제공하여 모든 전자 작업에서 신뢰성과 효율성을 보장합니다."}
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mt-8">
              <div className="bg-white shadow-xl rounded-xl p-8">
                <h1 className="text-3xl text-[#BFD9E7] mb-8">01</h1>
                <h2 className="text-xl font-semibold mb-2">
                  {language === "ENG" ? "Multi Layer" : "멀티 레이어"}
                </h2>
                <p>{language === "ENG" ? "(Over 64 Layer)" : "(64층 이상)"}</p>
              </div>
              <div className="bg-white shadow-xl rounded-xl p-8">
                <h1 className="text-3xl text-[#BFD9E7] mb-8">02</h1>
                <h2 className="text-xl font-semibold mb-2">
                  {language === "ENG"
                    ? "High Speed Interface"
                    : "고속 인터페이스"}
                </h2>
                <p>(USB2.0/S-ATA)</p>
              </div>
              <div className="bg-white shadow-xl rounded-xl p-8">
                <h1 className="text-3xl text-[#BFD9E7] mb-8">03</h1>
                <h2 className="text-xl font-semibold mb-2">
                  {language === "ENG" ? "High Frequency" : "고주파"}
                </h2>
                <p>
                  {language === "ENG"
                    ? "(Mobile Base Band)"
                    : "(모바일 베이스 밴드)"}
                </p>
              </div>
              <div className="bg-white shadow-xl rounded-xl p-8">
                <h1 className="text-3xl text-[#BFD9E7] mb-8">04</h1>
                <h2 className="text-xl font-semibold mb-2">
                  {language === "ENG"
                    ? "Impedance Controlled PCB"
                    : "임피던스 제어 PCB"}
                </h2>
                <p>
                  {language === "ENG"
                    ? "(50Ω+5Ω, Custom±10%, Special±5%)"
                    : "(50Ω+5Ω, 맞춤±10%, 특수±5%)"}
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Container className={"my-12 lg:my-20"}>
        <h3 className="font-bold text-2xl lg:text-3xl text-[#005D8D] mb-12">
          {"//"}
          {language === "ENG" ? " High Technology PCB" : " 첨단 기술 PCB"}
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-stretch">
          <div className="flex flex-col items-center bg-white rounded-xl shadow-xl">
            <h1 className="w-full py-8 text-xl text-center font-bold text-white bg-gradient-to-br from-[#005D8D] from-[60%] to-[#9ac6dd] rounded-t-xl">
              {language === "ENG" ? "Total PCB Design" : "전체 PCB 설계"}
            </h1>
            <div className="m-8">
              <ul>
                <li className="custom-list-item2">
                  {language === "ENG" ? "Multi-Layer PCB" : "다층 PCB"}
                </li>
                <li className="custom-list-item2">
                  {language === "ENG" ? "Digital / Analog" : "디지털/아날로그"}
                </li>
                <li className="custom-list-item2">
                  {language === "ENG" ? "Mixed Signal" : "혼합 신호"}
                </li>
                <li className="custom-list-item2">
                  {language === "ENG" ? "Memory Board" : "메모리 보드"}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-center bg-white rounded-xl shadow-xl">
            <h1 className="w-full py-8 text-lg text-center font-bold text-white bg-gradient-to-b from-[#005D8D] from-[60%] to-[#9ac6dd] rounded-t-xl">
              {language === "ENG"
                ? "Specialized in PCB Design & Fabrication"
                : "PCB 설계 및 제작 전문"}
            </h1>
            <div className="m-8">
              <ul>
                <li className="custom-list-item2">
                  {language === "ENG" ? "P/Card PCB" : "P/카드 PCB"}
                </li>
                <li className="custom-list-item2">
                  {language === "ENG" ? "Load Board PCB" : "로드 보드 PCB"}
                </li>
                <li className="custom-list-item2">
                  {language === "ENG" ? "PRVX M/Board" : "PRVX M/보드"}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-center bg-white rounded-xl shadow-xl">
            <h1 className="w-full py-8 text-xl text-center font-bold text-white bg-gradient-to-bl from-[#005D8D] from-[60%] to-[#9ac6dd] rounded-t-xl">
              {language === "ENG" ? "Turnkey Solution" : "턴키 솔루션"}
            </h1>
            <div className="m-8">
              <ul>
                <li className="custom-list-item2">
                  {language === "ENG" ? "Component Assembly" : "부품 조립"}
                </li>
                <li className="custom-list-item2">
                  {language === "ENG" ? "OEM Fabrication" : "OEM 제작"}
                </li>
                <li className="custom-list-item2">
                  {language === "ENG" ? "Part Sourcing" : "부품 소싱"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
      <div className="bg-[#f8f8f8]">
        <Container className={"py-16 lg:py-32"}>
          <div className="flex flex-col space-y-12 lg:flex-row lg:space-x-12 lg:space-y-0 justify-center items-center">
            <div className="grid grid-cols-2 gap-8 w-full lg:w-1/2">
              <div className="bg-white shadow-xl text-center">
                <img
                  src={ImgPCBDesignAllegro}
                  className="w-full"
                  alt="headline"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
                <h2 className="font-bold py-2">
                  {language === "ENG"
                    ? "Allegro (Cadence)"
                    : "알레그로(케이던스)"}
                </h2>
              </div>
              <div className="bg-white shadow-xl text-center">
                <img
                  src={ImgPCBDesignAutocad}
                  className="w-full"
                  alt="headline"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
                <h2 className="font-bold py-2">
                  {language === "ENG" ? "CAM350 Auto CAD" : "CAM350 자동 CAD"}
                </h2>
              </div>
              <div className="bg-white shadow-xl text-center">
                <img
                  src={ImgPCBDesignPcad}
                  className="w-full"
                  alt="headline"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
                <h2 className="font-bold py-2">
                  {language === "ENG" ? "P-CAD (Accel)" : "P-CAD(액셀)"}
                </h2>
              </div>
              <div className="bg-white shadow-xl text-center">
                <img
                  src={ImgPCBDesignPads}
                  className="w-full"
                  alt="headline"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
                <h2 className="font-bold py-2">
                  {language === "ENG" ? "PADS (Power PCB)" : "패드(전원 PCB)"}
                </h2>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <h1 className="font-bold text-3xl lg:text-5xl mb-8">
                {language === "ENG" ? "Design Tools" : "디자인 도구"}
              </h1>
              <p className="mb-4">
                {language === "ENG"
                  ? "Our tools merge efficiency and creativity, ensuring your PCB designs meet the highest standards of performance and reliability. Elevate your electronic engineering with tools crafted for the demands of tomorrow."
                  : "당사의 도구는 효율성과 창의성을 결합하여 PCB 설계가 최고 수준의 성능 및 신뢰성을 충족하도록 보장합니다. 미래의 요구에 맞게 제작된 도구를 사용하여 전자 엔지니어링을 향상시키십시오."}
              </p>
              <p className="mb-8">
                {language === "ENG"
                  ? "Do you have any questions? Feel free to contact us!"
                  : "질문있으세요? 저희에게 연락 주시기 바랍니다!"}
              </p>
              <a href="/contact-us">
                <button className="text-white font-medium px-4 py-2 bg-[#1e1e1e] hover:bg-opacity-75">
                  {language === "ENG" ? "CONTACT US" : "문의하기"}
                </button>
              </a>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
