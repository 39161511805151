import React from "react";
import Navbars from "../Components/Navbars";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import ButtonUp from "../Components/ButtonUp";
import Container from "../Components/Container";
import PlatformBackground from "../Assets/platform background.svg";
import PlatformUISection from "../Assets/platform ui section.png";
import PlatformVirtual from "../Assets/about us it service.svg";
import PlatformCompany from "../Assets/about us cad.svg";
import PlatformResponsive from "../Assets/about us gadgets.svg";
import PlatformIT from "../Assets/platform information technology.png";
import PlatformRetail from "../Assets/platform retail.png";
import PlatformBusiness from "../Assets/platform business.png";
import PlatformGaming from "../Assets/platform gaming.png";
import PlatformSectionBottom from "../Assets/platform section bottom.png";
import { Helmet } from "react-helmet-async";
import { useLanguage } from "../Context/LanguageContext";

export default function ITPlatform() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - IT Platform"
            : "PT. Meister Sinergi Indonesia - IT 플랫폼"}
        </title>
      </Helmet>
      <Navbars textblack currentBold={2} />
      <div className="background-container">
        <img
          src={PlatformBackground}
          alt="background"
          className="w-full"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
      </div>
      <Container
        className={
          "flex flex-col-reverse space-y-8 space-x-0 lg:flex-row items-center lg:space-y-0 lg:space-x-2 my-4 lg:my-12"
        }
      >
        <div className="w-full lg:w-3/4 lg:mt-0 mt-8 text-center lg:text-start">
          <h3 className="font-bold text-2xl mb-4 text-[#005D8D]">
            {"//"}
            {language === "ENG"
              ? " EVERYTHING YOU NEED"
              : " 너가 필요한 모든것"}
          </h3>
          <h1 className="font-bold text-4xl lg:text-5xl mb-8 leading-tight">
            {language === "ENG"
              ? "Build better platform"
              : "더 나은 플랫폼 구축"}
          </h1>
          <p className="mb-8">
            {language === "ENG"
              ? "Architecting the future, we specialize in crafting innovative platforms and software solutions — where every line of code is a blueprint for transformative digital experiences."
              : "미래를 설계하는 우리는 혁신적인 플랫폼과 소프트웨어 솔루션을 전문적으로 제작합니다. 여기서 모든 코드 라인은 혁신적인 디지털 경험을 위한 청사진입니다."}
          </p>
        </div>
        <div className="w-full">
          <img
            src={PlatformUISection}
            alt="UI"
            className="w-full"
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
        </div>
      </Container>
      <Container className={"py-12 lg:py-20"}>
        <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0">
          <div>
            <h3 className="font-bold text-xl lg:text-2xl mb-4 text-[#005D8D]">
              {"//"}
              {language === "ENG" ? " LATEST CASE STUDIES" : " 최신 사례 연구"}
            </h3>
            <h1 className="font-bold text-4xl lg:text-5xl mb-4 lg:mb-8 leading-tight">
              {language === "ENG"
                ? "Introduce our projects"
                : "우리 프로젝트를 소개해주세요"}
            </h1>
          </div>
          <div>
            <p className="mb-4 font-normal text-lg">
              {language === "ENG"
                ? "Our 10 years Experience brings you feel of reassurance for leaving your development needs to us!"
                : "우리의 10년 경험을 통해 귀하의 개발 요구 사항을 우리에게 맡길 수 있다는 확신을 갖게 되었습니다!"}
            </p>
            <p className="mb-8 font-normal text-lg">
              {language === "ENG"
                ? "Our 10 years of experience has convinced us that you can trust us with your development needs!"
                : "우리의 10년 경험을 통해 귀하의 개발 요구 사항을 우리에게 맡길 수 있다는 확신을 갖게 되었습니다!"}
            </p>
            <a href="/our-products">
              <button className="bg-[#005D8D] px-6 py-4 font-semibold text-white hover:bg-opacity-75">
                {language === "ENG" ? "VIEW ALL PRODUCTS" : "모든 제품 보기"}
              </button>
            </a>
          </div>
        </div>
        <div className="flex flex-col space-y-8 lg:flex-row my-12 lg:my-20 lg:space-x-8 lg:space-y-0">
          <div className="relative flex-grow">
            <img
              src={PlatformVirtual}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              className="rounded-xl w-full"
              alt="icon"
            />
            <div className="bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute bottom-0 left-0 right-0">
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "Virtual Reality" : "가상 현실"}
              </h1>
              <p>{language === "ENG" ? "IDEAS/DESIGN" : "아이디어/디자인"}</p>
            </div>
          </div>
          <div className="relative flex-grow">
            <img
              src={PlatformCompany}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              className="rounded-xl w-full"
              alt="icon"
            />
            <div className="bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute bottom-0 left-0 right-0">
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "Company Website" : "회사 웹 사이트"}
              </h1>
              <p>{language === "ENG" ? "DESIGN/DEVELOPMENT" : "디자인 개발"}</p>
            </div>
          </div>
          <div className="relative flex-grow">
            <img
              src={PlatformResponsive}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              className="rounded-xl w-full"
              alt="icon"
            />
            <div className="bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute bottom-0 left-0 right-0">
              <h1 className="font-bold text-2xl">
                {language === "ENG" ? "Responsive Design" : "반응형 디자인"}
              </h1>
              <p>
                {language === "ENG" ? "IDEAS/DEVELOPMENT" : "아이디어/개발"}
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className="bg-[#005D8D] py-28">
        <Container>
          <h1 className="text-center font-bold text-4xl lg:text-5xl mb-8 leading-tight text-white">
            {language === "ENG"
              ? "Solution for Utility Enterprise"
              : "유틸리티 기업을 위한 솔루션"}
          </h1>
          <p className="text-center text-xl font-medium text-white w-full lg:w-3/4 mx-auto">
            {language === "ENG"
              ? "We help businesses elevate their value through custom software development, product design, QA and consultancy services."
              : "우리는 맞춤형 소프트웨어 개발, 제품 디자인, QA 및 컨설팅 서비스를 통해 기업의 가치를 높일 수 있도록 돕습니다."}
          </p>
          <div className="flex flex-col space-y-8 space-x-0 lg:flex-row lg:space-x-8 lg:space-y-0 mt-12">
            <div className="flex flex-col justify-evenly space-y-8 bg-white bg-opacity-25 p-8 rounded-xl">
              <h1 className="text-[#f2c96f] font-bold text-2xl text-center">
                {language === "ENG"
                  ? "Custom software applications"
                  : "맞춤형 소프트웨어 애플리케이션"}
              </h1>
              <p className="font-normal text-lg text-white">
                {language === "ENG"
                  ? "Enhance your infrastructure using the capabilities of cloud technology, advanced microservices, and API integrations. Our specialized services in developing custom software platforms prioritize high availability and scalability, guaranteeing concrete achievements at each stage."
                  : "클라우드 기술, 고급 마이크로서비스, API 통합 기능을 사용하여 인프라를 강화하세요. 맞춤형 소프트웨어 플랫폼 개발에 대한 당사의 전문 서비스는 고가용성과 확장성을 우선시하여 각 단계에서 구체적인 성과를 보장합니다."}
              </p>
            </div>
            <div className="flex flex-col justify-evenly space-y-8 bg-white bg-opacity-25 p-8 rounded-xl">
              <h1 className="text-[#f2c96f] font-bold text-2xl text-center">
                {language === "ENG"
                  ? "Enterprise application development"
                  : "엔터프라이즈 애플리케이션 개발"}
              </h1>
              <p className="font-normal text-lg text-white">
                {language === "ENG"
                  ? "Elevate your infrastructure by harnessing the potential of cloud capabilities, sophisticated microservices, and API integrations. Our specialized services in developing custom software platforms prioritize high availability and scalability, guaranteeing tangible outcomes at each milestone."
                  : "클라우드 기능, 정교한 마이크로서비스, API 통합의 잠재력을 활용하여 인프라를 향상하세요. 맞춤형 소프트웨어 플랫폼 개발에 대한 당사의 전문 서비스는 고가용성과 확장성을 우선시하여 각 단계에서 실질적인 결과를 보장합니다."}
              </p>
            </div>
            <div className="flex flex-col justify-evenly space-y-8 bg-white bg-opacity-25 p-8 rounded-xl">
              <h1 className="text-[#f2c96f] font-bold text-2xl text-center">
                {language === "ENG" ? "IoT development" : "IoT 개발"}
              </h1>
              <p className="font-normal text-lg text-white">
                {language === "ENG"
                  ? "We deliver end-to-end IoT software development services, ranging from the initial concept to market-ready deployment. Our expertise encompasses UI/UX design, testing, deployment, and maintenance, assuring the reliability and user-friendliness of your customized IoT application."
                  : "우리는 초기 개념부터 시장에 바로 적용 가능한 배포까지 엔드투엔드 IoT 소프트웨어 개발 서비스를 제공합니다. 당사의 전문 지식은 UI/UX 디자인, 테스트, 배포 및 유지 관리를 포괄하여 맞춤형 IoT 애플리케이션의 안정성과 사용자 친화성을 보장합니다."}
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-white py-28">
        <Container>
          <h3 className="font-bold text-2xl mb-4 text-[#005D8D] text-center">
            {"//"}
            {language === "ENG" ? " BUSINESS INDUSTRIES" : " 비즈니스 산업"}
          </h3>
          <h1 className="font-bold text-5xl mb-12 leading-tight text-center">
            {language === "ENG"
              ? "Business industries we serve"
              : "우리가 서비스를 제공하는 비즈니스 산업"}
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="flex flex-col text-center rounded-xl shadow-xl">
              <img
                src={PlatformIT}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                className="rounded-xl w-full"
                alt="illustration"
              />
              <div className="p-8">
                <h1 className="font-bold text-3xl mb-4">
                  {language === "ENG" ? "Information Technology" : "정보 기술"}
                </h1>
                <p className="font-normal text-lg">
                  {language === "ENG"
                    ? "Elevate your business with the digital synergy of our IT company, where innovation meets strategy, and technology becomes a catalyst for your growth and success."
                    : "혁신이 전략을 충족하고 기술이 귀하의 성장과 성공을 위한 촉매제가 되는 IT 회사의 디지털 시너지 효과로 귀하의 비즈니스를 향상시키십시오."}
                </p>
              </div>
            </div>
            <div className="flex flex-col text-center rounded-xl shadow-xl">
              <img
                src={PlatformRetail}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                className="rounded-xl w-full"
                alt="illustration"
              />
              <div className="p-8">
                <h1 className="font-bold text-3xl mb-4">
                  {language === "ENG"
                    ? "Retail and Distribution"
                    : "소매 및 유통"}
                </h1>
                <p className="font-normal text-lg">
                  {language === "ENG"
                    ? "In the dynamic dance of commerce, our retail and distribution company is your partner, orchestrating seamless pathways from production to the hands of your eager customers, ensuring your business rhythm never misses a beat."
                    : "역동적인 상거래 속에서 당사의 소매 및 유통 회사는 귀하의 파트너로서 생산부터 열성적인 고객의 손에 이르기까지 원활한 경로를 조율하여 귀하의 비즈니스 리듬이 한 순간도 놓치지 않도록 보장합니다."}
                </p>
              </div>
            </div>
            <div className="flex flex-col text-center rounded-xl shadow-xl">
              <img
                src={PlatformBusiness}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                className="rounded-xl w-full"
                alt="illustration"
              />
              <div className="p-8">
                <h1 className="font-bold text-3xl mb-4">
                  {language === "ENG"
                    ? "Business and Finance"
                    : "비즈니스 및 금융"}
                </h1>
                <p className="font-normal text-lg">
                  {language === "ENG"
                    ? "Forge a path to financial prosperity with our business and finance company — where expertise meets innovation, and every strategy is a roadmap to your business's financial zenith."
                    : "전문 지식과 혁신이 만나 모든 전략이 비즈니스의 재정적 정점을 향한 로드맵이 되는 당사의 비즈니스 및 금융 회사와 함께 재정적 번영을 향한 길을 구축하십시오."}
                </p>
              </div>
            </div>
            <div className="flex flex-col text-center rounded-xl shadow-xl">
              <img
                src={PlatformGaming}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                className="rounded-xl w-full"
                alt="illustration"
              />
              <div className="p-8">
                <h1 className="font-bold text-3xl mb-4">
                  {language === "ENG"
                    ? "Gaming and Entertainment"
                    : "게임 및 엔터테인먼트"}
                </h1>
                <p className="font-normal text-lg">
                  {language === "ENG"
                    ? "Our gaming and entertainment company crafts experiences that captivate audiences, ensuring your brand doesn't just engage but becomes an unforgettable adventure."
                    : "우리의 게임 및 엔터테인먼트 회사는 청중을 사로잡는 경험을 만들어 귀하의 브랜드가 단순히 참여하는 데 그치지 않고 잊을 수 없는 모험이 되도록 보장합니다."}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#f8f8f8] pb-48 relative">
        <img
          src={PlatformSectionBottom}
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          className="object-cover w-full h-[300px] lg:h-auto"
          alt="section"
        />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white shadow-xl p-8 rounded-xl text-center lg:text-start w-[90%] lg:w-auto">
          <h1 className="font-bold text-3xl lg:text-4xl mb-4">
            {language === "ENG"
              ? "Need a consultation?"
              : "상담이 필요하신가요?"}
          </h1>
          <h3 className="text-[#005D8D] font-bold text-xl mb-12">
            {language === "ENG"
              ? "DROP US A LINE! WE ARE HERE TO ANSWER YOU 24/7"
              : "전화주세요! 연중무휴 24시간 답변해드리겠습니다."}
          </h3>
          <div className="flex justify-center">
            <a href="/contact-us">
              <button className="bg-[#005D8D] px-8 py-4 font-medium text-white rounded-xl">
                {language === "ENG" ? "CONTACT US" : "문의하기"}
              </button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
