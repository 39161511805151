import React from "react";
import ScrollToTop from "../Helper/ScrollToTop";
import Footer from "../Components/Footer";
import ButtonUp from "../Components/ButtonUp";
import Navbars from "../Components/Navbars";
import Container from "../Components/Container";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ImgHeadline from "../Assets/our products headline.svg";
import ImgBottom from "../Assets/our products bottom.png";
import ImgAboutIt from "../Assets/about us it service.svg";
import ImgAboutCad from "../Assets/about us cad.svg";
import ImgAboutpcb from "../Assets/about us gadgets.svg";
import { useLanguage } from "../Context/LanguageContext";
import { Helmet } from "react-helmet-async";

export default function OurProducts() {
  const { language } = useLanguage();

  const [activeTab, setActiveTab] = React.useState("All");
  const data = [
    {
      label: "All",
      value: "All",
      labelKOR: "모두",
      list: [
        {
          image: ImgAboutIt,
          name: "Virtual Reality",
          nameKOR: "가상 현실",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutCad,
          name: "Company Website",
          nameKOR: "회사 웹 사이트",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutpcb,
          name: "Responsive Design",
          nameKOR: "반응형 디자인",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutIt,
          name: "ICEP Website",
          nameKOR: "ICEP 웹사이트",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutCad,
          name: "TOPIK III Course",
          nameKOR: "TOPIK III 코스",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutpcb,
          name: "Meister Website",
          nameKOR: "마이스터 홈페이지",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutpcb,
          name: "Application 1",
          nameKOR: "애플리케이션 1",
          desc: "Application",
          descKOR: "애플리케이션",
        },
        {
          image: ImgAboutCad,
          name: "Application 2",
          nameKOR: "애플리케이션 2",
          desc: "Application",
          descKOR: "애플리케이션",
        },
        {
          image: ImgAboutIt,
          name: "Application 3",
          nameKOR: "애플리케이션 3",
          desc: "Application",
          descKOR: "애플리케이션",
        },
        {
          image: ImgAboutIt,
          name: "Platform 1",
          nameKOR: "플랫폼 1",
          desc: "Platform",
          descKOR: "플랫폼",
        },
        {
          image: ImgAboutpcb,
          name: "Platform 2",
          nameKOR: "플랫폼 2",
          desc: "Platform",
          descKOR: "플랫폼",
        },
        {
          image: ImgAboutCad,
          name: "Platform 3",
          nameKOR: "플랫폼 3",
          desc: "Platform",
          descKOR: "플랫폼",
        },
        {
          image: ImgAboutCad,
          name: "ERP 1",
          nameKOR: "ERP 1",
          desc: "ERP",
          descKOR: "ERP",
        },
        {
          image: ImgAboutpcb,
          name: "ERP 2",
          nameKOR: "ERP 2",
          desc: "ERP",
          descKOR: "ERP",
        },
        {
          image: ImgAboutIt,
          name: "ERP 3",
          nameKOR: "ERP 3",
          desc: "ERP",
          descKOR: "ERP",
        },
      ],
    },
    {
      label: "Website",
      value: "Website",
      labelKOR: "웹사이트",
      list: [
        {
          image: ImgAboutIt,
          name: "Virtual Reality",
          nameKOR: "가상 현실",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutCad,
          name: "Company Website",
          nameKOR: "회사 웹 사이트",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutpcb,
          name: "Responsive Design",
          nameKOR: "반응형 디자인",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutIt,
          name: "ICEP Website",
          nameKOR: "ICEP 웹사이트",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutCad,
          name: "TOPIK III Course",
          nameKOR: "TOPIK III 코스",
          desc: "Website",
          descKOR: "웹사이트",
        },
        {
          image: ImgAboutpcb,
          name: "Meister Website",
          nameKOR: "마이스터 홈페이지",
          desc: "Website",
          descKOR: "웹사이트",
        },
      ],
    },
    {
      label: "Application",
      value: "Application",
      labelKOR: "애플리케이션",
      list: [
        {
          image: ImgAboutpcb,
          name: "Application 1",
          nameKOR: "애플리케이션 1",
          desc: "Application",
          descKOR: "애플리케이션",
        },
        {
          image: ImgAboutCad,
          name: "Application 2",
          nameKOR: "애플리케이션 2",
          desc: "Application",
          descKOR: "애플리케이션",
        },
        {
          image: ImgAboutIt,
          name: "Application 3",
          nameKOR: "애플리케이션 3",
          desc: "Application",
          descKOR: "애플리케이션",
        },
      ],
    },
    {
      label: "Platform",
      value: "Platform",
      labelKOR: "플랫폼",
      list: [
        {
          image: ImgAboutIt,
          name: "Platform 1",
          nameKOR: "플랫폼 1",
          desc: "Platform",
          descKOR: "플랫폼",
        },
        {
          image: ImgAboutpcb,
          name: "Platform 2",
          nameKOR: "플랫폼 2",
          desc: "Platform",
          descKOR: "플랫폼",
        },
        {
          image: ImgAboutCad,
          name: "Platform 3",
          nameKOR: "플랫폼 3",
          desc: "Platform",
          descKOR: "플랫폼",
        },
      ],
    },
    {
      label: "ERP",
      value: "ERP",
      labelKOR: "ERP",
      list: [
        {
          image: ImgAboutCad,
          name: "ERP 1",
          nameKOR: "ERP 1",
          desc: "ERP",
          descKOR: "ERP",
        },
        {
          image: ImgAboutpcb,
          name: "ERP 2",
          nameKOR: "ERP 2",
          desc: "ERP",
          descKOR: "ERP",
        },
        {
          image: ImgAboutIt,
          name: "ERP 3",
          nameKOR: "ERP 3",
          desc: "ERP",
          descKOR: "ERP",
        },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - Our Products"
            : "PT. Meister Sinergi Indonesia - 우리의 제품"}
        </title>
      </Helmet>
      <div className="relative">
        <Navbars currentBold={1} />
        <img
          src={ImgHeadline}
          className="object-cover w-full h-[275px] lg:h-auto absolute top-0 left-0 -z-10"
          alt="headline"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
        <Container>
          <h1 className="text-center lg:text-start text-white font-semibold text-5xl mt-20">
            Our Products
          </h1>
        </Container>
      </div>
      <Container className={"mt-28 text-center"}>
        <h3 className="text-[#005D8D] font-bold text-2xl">
          {"//"} {language === "ENG" ? "LATEST CASE STUDIES" : "최신 사례 연구"}
        </h3>
        <h1 className="font-bold text-3xl lg:text-5xl my-8">
          {language === "ENG"
            ? "Introduce our products"
            : "우리의 제품을 소개합니다"}
        </h1>
        <p className="text-xl w-full lg:w-3/4 mx-auto">
          {language === "ENG"
            ? "Software development outsourcing is just a tool to achieve business goals. But there is no way to get worthwhile results without cooperation and trust between a client company."
            : "소프트웨어 개발 아웃소싱은 비즈니스 목표를 달성하기 위한 도구일 뿐입니다. 하지만 고객사와의 협력과 신뢰 없이는 가치 있는 결과를 얻을 수 없습니다."}
        </p>
      </Container>
      <Container className={"my-12 lg:my-20"}>
        <Tabs value={activeTab}>
          <TabsHeader
            className="rounded-none border-b-2 border-[#EAF2EE] bg-transparent p-0 lg:w-3/4 mx-auto"
            indicatorProps={{
              className:
                "bg-transparent border-b-4 border-[#005D8D] shadow-none rounded-none",
            }}
          >
            {data &&
              data.map(({ label, value, labelKOR }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className={
                    activeTab === value
                      ? "text-[#005D8D] font-bold text-md lg:text-lg"
                      : "text-[#005D8D] font-normal text-md lg:text-lg"
                  }
                >
                  {language === "ENG" ? label : labelKOR}
                </Tab>
              ))}
          </TabsHeader>
          <TabsBody>
            {data &&
              data.map(({ value, list }) => (
                <TabPanel key={value} value={value} className="!p-0">
                  <div className="grid grid-cols-1 gap-8 my-4 lg:grid-cols-3 lg:my-16">
                    {list &&
                      list.map((item) => (
                        <div className="relative flex-grow">
                          <img
                            src={item.image}
                            onContextMenu={(e) => e.preventDefault()}
                            draggable="false"
                            className="rounded-xl w-full maxh-[245px]"
                            alt="icon"
                          />
                          <div className="bg-[#005D8D] bg-opacity-35 rounded-b-xl text-white w-full py-4 px-4 absolute bottom-0 left-0 right-0">
                            <h1 className="font-bold">
                              {language === "ENG" ? item.name : item.nameKOR}
                            </h1>
                            <p>
                              {language === "ENG" ? item.desc : item.descKOR}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </TabPanel>
              ))}
          </TabsBody>
        </Tabs>
      </Container>
      <div className="my-12 lg:my-20 mx-8 lg:mx-12 bg-[#BFD9E7] rounded-2xl">
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-y-0 pt-12 lg:pt-20 px-8 lg:px-16 items-center">
          <div className="shrink-0 lg:shrink text-center lg:text-start">
            <h1 className="font-bold text-3xl lg:text-5xl mb-8">
              {language === "ENG"
                ? "Ready to get started?"
                : "시작할 준비가 되셨나요?"}
            </h1>
            <p className="mb-8">
              {language === "ENG"
                ? "Connect with us effortlessly. Your queries, our priority. Reach out today — because your message is the key to unlocking solutions."
                : "우리와 쉽게 연결하세요. 귀하의 질문이 우리의 우선순위입니다. 지금 연락하세요. 귀하의 메시지가 솔루션을 여는 열쇠이기 때문입니다."}
            </p>
            <a href={"/contact-us"} className="w-full">
              <button className="bg-[#005D8D] text-white font-semibold px-8 py-2 hover:bg-opacity-75 transition-all">
                {language === "ENG" ? "LET'S START" : "시작하자"}
              </button>
            </a>
          </div>
          <img
            src={ImgBottom}
            className="w-full lg:w-1/2 lg:self-end"
            alt="bottom"
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
        </div>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
