import React from "react";
import Navbars from "../Components/Navbars";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import ButtonUp from "../Components/ButtonUp";
import Container from "../Components/Container";
import ErpBackground from "../Assets/erp background.png";
import ErpLaptop from "../Assets/erp laptop background.png";
import ErpCustomPlatform from "../Assets/erp custom platform.png";
import ErpOutsourcing from "../Assets/erp outsourcing.png";
import ErpSoftware from "../Assets/erp software app.png";
import ErpPhp from "../Assets/erp php.png";
import ErpReact from "../Assets/erp react.png";
import ErpJs from "../Assets/erp js.png";
import ErpCss from "../Assets/erp css.png";
import ErpSectionBackground from "../Assets/erp section background.png";
import ErpComputerBottom from "../Assets/erp computer bottom.png";
import ErpSoftwareService from "../Assets/erp Software as service.png";
import ErpIoT from "../Assets/erp internet of things.png";
import ErpAi from "../Assets/erp artificial intelligence.png";
import ErpSocialMedia from "../Assets/erp social media.png";
import ErpSoftwareDev from "../Assets/erp software development.png";
import ErpContent from "../Assets/erp content creation.png";
import { Helmet } from "react-helmet-async";
import { useLanguage } from "../Context/LanguageContext";

export default function ITErpDevelopment() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - IT ERP Development"
            : "PT. Meister Sinergi Indonesia - IT ERP 개발"}
        </title>
      </Helmet>
      <Navbars textblack currentBold={2} />
      <div className="background-container">
        <img
          src={ErpBackground}
          alt="background"
          className="object-cover w-full h-[400px] lg:h-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
      </div>
      <Container
        className={
          "flex flex-col-reverse space-y-8 space-x-0 lg:flex-row items-center lg:space-x-2 lg:space-y-0 my-8 lg:my-20"
        }
      >
        <div className="w-full lg:w-3/4 mt-12 lg:mt-0 text-center lg:text-start">
          <h3 className="font-bold text-xl lg:text-2xl mb-4 text-[#005D8D]">
            {"//"}
            {language === "ENG" ? " FOR OVER 15 YEARS" : " 15년 넘게"}
            <br />
            {language === "ENG"
              ? "FOR MILLIONS OF USERS"
              : "수백만 명의 사용자를 위한"}
          </h3>
          <h1 className="font-bold text-4xl lg:text-5xl mb-8 leading-tight">
            {language === "ENG"
              ? "Enterprise Apps Development"
              : "엔터프라이즈 앱 개발"}
          </h1>
          <p className="mb-8">
            {language === "ENG"
              ? "We are 100+ professional software engineers with more than 10 years of experience in delivering superior products."
              : "우리는 우수한 제품을 제공하는 데 10년 이상의 경험을 가진 100명 이상의 전문 소프트웨어 엔지니어입니다."}
          </p>
        </div>
        <div className="w-full">
          <img
            src={ErpLaptop}
            alt="laptop"
            className="w-full"
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
        </div>
      </Container>
      <div className="bg-white py-16 lg:py-28">
        <Container>
          <div
            className={
              "grid grid-cols-1 lg:grid-cols-3 gap-8 justify-between justify-items-end"
            }
          >
            <div className="flex flex-col space-y-8 items-center text-center">
              <img
                src={ErpCustomPlatform}
                alt="icon"
                className="w-1/3"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <h1 className="font-bold text-3xl">
                {language === "ENG"
                  ? "Custom Platform Development"
                  : "맞춤형 플랫폼 개발"}
              </h1>
              <a href="/platform-development">
                <button className="bg-[#005D8D] px-6 py-4 font-semibold text-white hover:bg-opacity-75">
                  {language === "ENG" ? "LEARN MORE" : "더 알아보기"}
                </button>
              </a>
            </div>
            <div className="flex flex-col space-y-8 items-center text-center">
              <img
                src={ErpOutsourcing}
                alt="icon"
                className="w-1/3"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <h1 className="font-bold text-3xl">
                {language === "ENG"
                  ? "Outsourcing Development"
                  : "아웃소싱 개발"}
              </h1>
              <a href="/cam-outsourcing">
                <button className="bg-[#005D8D] px-6 py-4 font-semibold text-white hover:bg-opacity-75">
                  {language === "ENG" ? "LEARN MORE" : "더 알아보기"}
                </button>
              </a>
            </div>
            <div className="flex flex-col space-y-8 items-center text-center">
              <img
                src={ErpSoftware}
                alt="icon"
                className="w-1/3"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
              <h1 className="font-bold text-3xl">
                {language === "ENG"
                  ? "Software App Development"
                  : "소프트웨어 앱 개발"}
              </h1>
              <a href="/app-development">
                <button className="bg-[#005D8D] px-6 py-4 font-semibold text-white hover:bg-opacity-75">
                  {language === "ENG" ? "LEARN MORE" : "더 알아보기"}
                </button>
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#005D8D] py-28">
        <Container>
          <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0 items-center">
            <div className="w-full lg:w-3/4 text-center lg:text-start">
              <h3 className="font-bold text-2xl mb-4 text-[#f2c96f]">
                {"//"} {language === "ENG" ? "FOR OVER 15 YEARS" : "15년 넘게"}
                <br />
                {language === "ENG"
                  ? "FOR MILLIONS OF USERS"
                  : "수백만 명의 사용자를 위한"}
              </h3>
              <h1 className="font-bold text-4xl lg:text-5xl mb-8 leading-tight text-white">
                {language === "ENG"
                  ? "What we actually do"
                  : "우리가 실제로 하는 일"}
              </h1>
              <div className="grid grid-cols-4 gap-4 w-full lg:w-3/4">
                <img
                  src={ErpPhp}
                  alt="icon"
                  className="w-full"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
                <img
                  src={ErpReact}
                  alt="icon"
                  className="w-full"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
                <img
                  src={ErpJs}
                  alt="icon"
                  className="w-full"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
                <img
                  src={ErpCss}
                  alt="icon"
                  className="w-full"
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
              </div>
              <p className="text-white font-medium mt-8">
                {language === "ENG"
                  ? "PT. Meister Sinergi Indonesia is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services."
                  : "PT. Meister Sinergi Indonesia는 세계 최고의 기업, 중소기업 및 기술 도전자들이 선택하는 파트너입니다. 우리는 맞춤형 소프트웨어 개발, 제품 디자인, QA 및 컨설팅 서비스를 통해 기업의 가치를 높일 수 있도록 돕습니다."}
              </p>
            </div>
            <div className="w-full">
              <img
                src={ErpSectionBackground}
                alt="background section"
                className="w-full"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-white py-28">
        <Container>
          <h3 className="font-bold text-2xl mb-8 text-[#005D8D] text-center">
            {"//"}
            {language === "ENG" ? " BUSINESS BENEFITS" : " 비즈니스 이점"}
          </h3>
          <h1 className="font-bold text-4xl lg:text-5xl mb-12 lg:mb-16 leading-tight text-center">
            {language === "ENG" ? "Our service benefits" : "우리의 서비스 혜택"}
          </h1>
          <div className="flex flex-col space-y-8 space-x-0 lg:flex-row lg:space-x-12 lg:space-y-0">
            <div className="flex flex-col space-y-16">
              <div className="flex flex-row items-center space-x-4">
                <div className="text-right">
                  <h1 className="text-2xl font-bold mb-2">
                    {language === "ENG"
                      ? "Software as a Service"
                      : "서비스로서의 소프트웨어"}
                  </h1>
                  <p className="font-regular ml-auto">
                    {language === "ENG"
                      ? "Run your own startup with a SaaS model to start earning for you"
                      : "SaaS 모델로 자신만의 스타트업을 운영하여 수익을 창출해 보세요."}
                  </p>
                </div>
                <div>
                  <img
                    src={ErpSoftwareService}
                    alt="Icon"
                    className="w-[100px] h-auto"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center space-x-4">
                <div className="text-right">
                  <h1 className="text-2xl font-bold mb-2">
                    {language === "ENG" ? "Internet of Things" : "사물 인터넷"}
                  </h1>
                  <p className="font-regular ml-auto">
                    {language === "ENG"
                      ? "Let the robot automate your tasks in palm of your hand"
                      : "로봇이 당신의 손 안에서 작업을 자동화하게 하세요"}
                  </p>
                </div>
                <div>
                  <img
                    src={ErpIoT}
                    alt="Icon"
                    className="w-[100px] h-auto"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center space-x-4">
                <div className="text-right">
                  <h1 className="text-2xl font-bold mb-2">
                    {language === "ENG" ? "AI and Automation" : "AI와 자동화"}
                  </h1>
                  <p className="font-regular ml-auto">
                    {language === "ENG"
                      ? "Develop with the recent technology of AI Training supported by OpenAI"
                      : "OpenAI가 지원하는 최신 AI Training 기술로 개발"}
                  </p>
                </div>
                <div>
                  <img
                    src={ErpAi}
                    alt="Icon"
                    className="w-[100px] h-auto"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                  />
                </div>
              </div>
            </div>
            <div className="self-center">
              <img
                src={ErpComputerBottom}
                alt="background section"
                className="w-full lg:w-3/4 my-12 lg:my-0 mx-auto"
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            </div>
            <div className="flex flex-col space-y-16">
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <img
                    src={ErpSocialMedia}
                    alt="Icon"
                    className="w-[100px] h-auto"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                  />
                </div>
                <div className="text-start">
                  <h1 className="text-2xl font-bold mb-2">
                    {language === "ENG" ? "Social Media" : "소셜 미디어"}
                  </h1>
                  <p className="font-regular mr-auto">
                    {language === "ENG"
                      ? "Market yourself in the social media with our digital marketing services"
                      : "당사의 디지털 마케팅 서비스를 통해 소셜 미디어에서 자신을 홍보하세요."}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <img
                    src={ErpSoftwareDev}
                    alt="Icon"
                    className="w-[100px] h-auto"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                  />
                </div>
                <div className="text-start">
                  <h1 className="text-2xl font-bold mb-2">
                    {language === "ENG"
                      ? "Software Development"
                      : "소프트웨어 개발"}
                  </h1>
                  <p className="font-regular mr-auto">
                    {language === "ENG"
                      ? "Build a software for your business needs from desktop to mobile"
                      : "데스크탑에서 모바일까지 비즈니스 요구에 맞는 소프트웨어 구축"}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <img
                    src={ErpContent}
                    alt="Icon"
                    className="w-[100px] h-auto"
                    onContextMenu={(e) => e.preventDefault()}
                    draggable="false"
                  />
                </div>
                <div className="text-start">
                  <h1 className="text-2xl font-bold mb-2">
                    {language === "ENG" ? "Content Creation" : "콘텐츠 제작"}
                  </h1>
                  <p className="font-regular mr-auto">
                    {language === "ENG"
                      ? "Create a catchy video content for your tiktok, instagram, or shorts video"
                      : "tiktok, Instagram 또는 Shorts 동영상에 대한 눈길을 끄는 동영상 콘텐츠를 만드세요."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
