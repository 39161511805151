import { Fragment } from "react";
import { Disclosure, Menu, Transition, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FaCaretDown } from "react-icons/fa6";
import LogoMeister from "../Assets/logo1.png";
import { useLanguage } from "../Context/LanguageContext";
import { HiGlobeAsiaAustralia } from "react-icons/hi2";

const navigation = [
  {
    name: "Company",
    nameKOR: "회사",
    current: false,
    subMenu: [
      {
        name: "About Us",
        nameKOR: "회사 소개",
        href: "/about-us",
      },
      {
        name: "Our Products",
        nameKOR: "우리의 제품",
        href: "/our-products",
      },
    ],
  },
  {
    name: "IT Services",
    nameKOR: "IT 서비스",
    current: false,
    subMenu: [
      {
        name: "Web Development",
        nameKOR: "웹 개발",
        href: "/web-development",
      },
      {
        name: "ERP Development",
        nameKOR: "ERP 개발",
        href: "/erp-development",
      },
      {
        name: "Platform",
        nameKOR: "플랫폼",
        href: "/platform-development",
      },
      {
        name: "App Development",
        nameKOR: "앱 개발",
        href: "/app-development",
      },
    ],
  },
  {
    name: "CAD / CAM",
    nameKOR: "캐드/캠",
    current: false,
    subMenu: [
      {
        name: "Autocam V2",
        nameKOR: "오토캠 V2",
        href: "/autocam-v2",
      },
      {
        name: "CAM Outsourcing",
        nameKOR: "CAM 아웃소싱",
        href: "/cam-outsourcing",
      },
      {
        name: "PCB Design",
        nameKOR: "PCB 설계",
        href: "/pcb-design",
      },
    ],
  },
  {
    name: "PCB Production",
    nameKOR: "PCB 생산",
    current: false,
    subMenu: [
      {
        name: "Buildup PCB",
        nameKOR: "빌드업 PCB",
        href: "/buildup-pcb",
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbars({ textblack, currentBold, invisibleLogo }) {
  const { language, handleLanguage } = useLanguage();

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-24 items-center justify-between">
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/" className="hover:opacity-75">
                    {invisibleLogo ? (
                      <img
                        className="h-16 w-auto lg:visible invisible"
                        src={LogoMeister}
                        alt="Meister Sinergi"
                      />
                    ) : (
                      <img
                        className="h-16 w-auto"
                        src={LogoMeister}
                        alt="Meister Sinergi"
                      />
                    )}
                  </a>
                </div>
              </div>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button className="flex flex-row justify-center items-center lg:hidden outline-none">
                      <HiGlobeAsiaAustralia
                        className={`block h-8 w-8 ${
                          textblack ? "text-black" : "text-white"
                        }`}
                      />
                      <FaCaretDown
                        className={
                          open
                            ? `block h-4 w-4 self-center text-center transition-transform rotate-180 transform ${
                                textblack ? "text-black" : "text-white"
                              }`
                            : `block h-4 w-4 self-center text-center transition-transform -rotate-0 transform ${
                                textblack ? "text-black" : "text-white"
                              }`
                        }
                      />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-0"
                      enterTo="opacity-100 translate-y-1"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-1"
                      leaveTo="opacity-0 translate-y-0"
                    >
                      <Popover.Panel className="bg-white top-10 right-2 absolute z-10 flex flex-col rounded-xl">
                        {({ close }) => (
                          <>
                            <button
                              className="hover:rounded-t-xl hover:underline hover:bg-gray-300 px-3 py-1 border-b border-[#9a9a9a]"
                              onClick={() => {
                                language === "KOR" && handleLanguage("ENG");
                                close();
                              }}
                            >
                              <span
                                className={`${
                                  language === "ENG" && "font-bold"
                                }`}
                              >
                                ENG
                              </span>
                            </button>
                            <button
                              className="hover:rounded-b-xl hover:underline hover:bg-gray-300 px-3 py-1"
                              onClick={() => {
                                language === "ENG" && handleLanguage("KOR");
                                close();
                              }}
                            >
                              <span
                                className={`${
                                  language === "KOR" && "font-bold"
                                }`}
                              >
                                KOR
                              </span>
                            </button>
                          </>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <div className="hidden sm:ml-6 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Menu
                        as="div"
                        key={item.name}
                        className="group relative items-center"
                      >
                        <Menu.Button
                          className={classNames(
                            "relative flex group rounded-md px-3 py-2 text-sm items-center",
                            textblack ? "text-[#1a1a1a]" : "text-white",
                            (currentBold === 1 && item.name === "Company") ||
                              (currentBold === 2 &&
                                item.name === "IT Services") ||
                              (currentBold === 3 &&
                                item.name === "CAD / CAM") ||
                              (currentBold === 4 &&
                                item.name === "PCB Production")
                              ? "font-bold"
                              : "font-normal"
                          )}
                        >
                          <span className="group-hover:underline">
                            {language === "ENG" ? item.name : item.nameKOR}
                          </span>
                          <FaCaretDown
                            className={classNames(
                              textblack
                                ? "h-4 w-4 text-[#1a1a1a] ml-2 transition-all transform group-hover:translate-y-1"
                                : "h-4 w-4 text-white ml-2 hover:text-[#1a1a1a] transition-all transform group-hover:translate-y-1"
                            )}
                          />
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {item.subMenu &&
                              item.subMenu.map((subItem) => (
                                <Menu.Item key={subItem.name}>
                                  {({ active }) => (
                                    <a
                                      href={subItem.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-[#1a1a1a]"
                                      )}
                                    >
                                      {language === "ENG"
                                        ? subItem.name
                                        : subItem.nameKOR}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ))}
                    <a
                      href={"/contact-us"}
                      className={classNames(
                        "hover:underline rounded-md px-3 py-2 text-sm font-normal items-center",
                        textblack
                          ? "text-[#1a1a1a] border-black"
                          : "text-white border-white",
                        currentBold === 5 ? "!font-bold" : ""
                      )}
                    >
                      {language === "ENG" ? "Contact Us" : "연락처"}
                    </a>
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button className="lg:flex lg:flex-row justify-center items-center hidden outline-none ml-8 py-1">
                            <HiGlobeAsiaAustralia
                              className={`block h-8 w-8 self-center text-center ${
                                textblack ? "text-black" : "text-white"
                              }`}
                            />
                            <FaCaretDown
                              className={
                                open
                                  ? `block h-4 w-4 self-center text-center transition-transform rotate-180 transform ${
                                      textblack ? "text-black" : "text-white"
                                    }`
                                  : `block h-4 w-4 self-center text-center transition-transform -rotate-0 transform ${
                                      textblack ? "text-black" : "text-white"
                                    }`
                              }
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-0"
                            enterTo="opacity-100 translate-y-1"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-1"
                            leaveTo="opacity-0 translate-y-0"
                          >
                            <Popover.Panel className="bg-white top-10 right-2 absolute z-10 flex flex-col rounded-xl">
                              {({ close }) => (
                                <>
                                  <button
                                    className="hover:rounded-t-xl hover:underline hover:bg-gray-300 px-3 py-1 border-b border-[#9a9a9a]"
                                    onClick={() => {
                                      language === "KOR" &&
                                        handleLanguage("ENG");
                                      close();
                                    }}
                                  >
                                    <span
                                      className={`${
                                        language === "ENG" && "font-bold"
                                      }`}
                                    >
                                      ENG
                                    </span>
                                  </button>
                                  <button
                                    className="hover:rounded-b-xl hover:underline hover:bg-gray-300 px-3 py-1"
                                    onClick={() => {
                                      language === "ENG" &&
                                        handleLanguage("KOR");
                                      close();
                                    }}
                                  >
                                    <span
                                      className={`${
                                        language === "KOR" && "font-bold"
                                      }`}
                                    >
                                      KOR
                                    </span>
                                  </button>
                                </>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div
              className={classNames(
                "absolute z-50 flex flex-col w-full space-y-1 px-2 pb-3 pt-2",
                textblack ? "bg-gray-300" : "bg-gray-500"
              )}
            >
              {navigation.map((item) => (
                <Menu as="div" key={item.name} className="group relative">
                  <Menu.Button
                    className={classNames(
                      "relative w-full justify-between group rounded-md px-3 py-2 text-sm items-center",
                      textblack ? "text-[#1a1a1a]" : "text-white",
                      (currentBold === 1 && item.name === "Company") ||
                        (currentBold === 2 && item.name === "IT Services") ||
                        (currentBold === 3 && item.name === "CAD / CAM") ||
                        (currentBold === 4 && item.name === "PCB Production")
                        ? "font-bold"
                        : "font-normal"
                    )}
                  >
                    <span className="group-hover:underline">
                      {language === "ENG" ? item.name : item.nameKOR}
                    </span>
                    <FaCaretDown
                      className={classNames(
                        textblack
                          ? "h-4 w-full text-[#1a1a1a] transition-all transform group-hover:translate-y-1"
                          : "h-4 w-full text-white transition-all transform group-hover:translate-y-1"
                      )}
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="w-full absolute z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {item.subMenu &&
                        item.subMenu.map((subItem) => (
                          <Menu.Item key={subItem.name}>
                            {({ active }) => (
                              <a
                                href={subItem.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-[#1a1a1a]"
                                )}
                              >
                                {language === "ENG"
                                  ? subItem.name
                                  : subItem.nameKOR}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              ))}
              <a
                href={"/contact-us"}
                className={classNames(
                  "text-center rounded-md px-3 py-2 text-sm font-normal items-center",
                  textblack ? "text-[#1a1a1a] hover:text-white" : "text-white",
                  currentBold === 5 ? "!font-bold" : ""
                )}
              >
                {language === "ENG" ? "Contact Us" : "연락처"}
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
