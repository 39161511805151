import React from "react";
import Navbars from "../Components/Navbars";
import ImgBuildUp from "../Assets/pcb headline.webp";
import Footer from "../Components/Footer";
import ScrollToTop from "../Helper/ScrollToTop";
import ButtonUp from "../Components/ButtonUp";
import Container from "../Components/Container";
import ImgPcbLeft from "../Assets/pcb left.png";
import ImgPcbRight from "../Assets/pcb right.png";
import ImgPcbLeft2 from "../Assets/pcb left 2.png";
import ImgPcbRight2 from "../Assets/pcb right 2.png";
import ImgPcbBottom from "../Assets/pcb bottom 2.webp";
import { useLanguage } from "../Context/LanguageContext";
import { Helmet } from "react-helmet-async";

export default function BuildPcb() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - Buildup PCB"
            : "PT. Meister Sinergi Indonesia - 빌드업 PCB"}
        </title>
      </Helmet>
      <Navbars textblack currentBold={4} />
      <div className="background-container">
        <img
          src={ImgBuildUp}
          alt="teamwork"
          className="h-[700px] lg:w-full lg:h-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
      </div>
      <Container className="text-center my-20">
        <h1 className="font-bold text-5xl mb-12">
          {language === "ENG" ? "Build up PCB Production" : "PCB 생산 구축"}
        </h1>
        <h2 className="font-bold text-3xl mb-8 text-[#005D8D]">
          {language === "ENG"
            ? "What is the Build Up method?"
            : "Build Up 공법이란?"}
        </h2>
        <p className="w-3/4 mx-auto">
          {language === "ENG"
            ? "Origin: It is a method of manufacturing PCBs by stacking them layer by layer. By stacking them like a building, it is possible to respond to the continuous decrease in size and thickness?"
            : "유래 : 한층 씩 쌓아 올리면서 PCB를 제작하는 공법으로, 빌딩과 같이 쌓아 올려 제작하는 것으로 Size, Thick’가 계속 줄어드는 것에 대응이 가능하며"}
          <span className="text-[#005D8D] font-bold">
            {language === "ENG"
              ? " Greater space utilization and higher spatial freedom than existing products"
              : " 기존의 제품보다 공간의 활용폭이 높고 공간 자유도가 높은"}
          </span>
          {language === "ENG"
            ? " Referring to a product as a PCB"
            : " PCB로 제품을 말함"}
        </p>
        <div className="flex flex-col space-y-4 lg:flex-row flex-grow lg:space-x-8 lg:space-y-0 mt-12 mb-20">
          <div className="flex flex-col justify-center items-center bg-white shadow-xl rounded-xl p-8">
            <img
              src={ImgPcbLeft}
              alt="pcb"
              className="w-1/2 mx-auto"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
            <p className="text-[#5a5aff] font-bold text-xl mt-4">
              {language === "ENG"
                ? "< General MLB products >"
                : "< 일반 MLB 제품 >"}
            </p>
          </div>
          <div className="flex flex-col justify-center items-center bg-white shadow-xl rounded-xl p-8">
            <img
              src={ImgPcbRight}
              alt="pcb"
              className="w-1/2 mx-auto"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
            <p className="text-[#5a5aff] font-bold text-xl mt-4">
              {language === "ENG"
                ? "< Build-up product >"
                : "< Build-up 제품 >"}
            </p>
          </div>
        </div>
      </Container>
      <div className="bg-white py-20">
        <Container className={"text-center"}>
          <h1 className="font-bold text-3xl mb-8 text-[#005D8D]">
            {language === "ENG" ? "Build-up Structure" : "빌드업 구조"}
          </h1>
          <p className="w-3/4 mx-auto">
            {language === "ENG"
              ? "PCB space freedom can be secured by reducing size and thickness and making design easier."
              : "사이즈 및 두께 축소, 설계 용이해짐으로 PCB 공간 자유도 확보 가능"}
          </p>
          <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-8 lg:space-y-0 justify-center mt-12">
            <img
              src={ImgPcbLeft2}
              alt="pcb"
              className="w-full lg:w-1/2 mx-auto"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
            <img
              src={ImgPcbRight2}
              alt="pcb"
              className="w-full lg:w-1/2 mx-auto"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
          </div>
        </Container>
      </div>
      <div className="bg-white">
        <Container className={"pt-8 lg:pt-20 pb-12 w-full"}>
          <h1 className="text-[#005D8D] text-4xl lg:text-5xl font-bold mb-8 text-center lg:text-start">
            {language === "ENG"
              ? "Standard delivery date by floor and specification"
              : "층별 사양별 표준 납기"}
          </h1>
          <p className="font-medium text-xl">
            {language === "ENG"
              ? "The table below shows the standard delivery time and price for each Build UP floor and specification, which are finalized after prior consultation (8 to 12 days)."
              : "아래표는 Build UP 층별 사양별 표준 납기와 가격으로 사전 협의후에 최종 결정 됩니다 ( 8일 ~ 12일 )"}
          </p>
        </Container>
      </div>
      <div className="background-table-pcb">
        <Container className={"!p-0"}>
          <div className="max-w-full overflow-x-auto lg:overflow-x-hidden">
            <table className="!font-semibold border-separate border-spacing-1 m-8">
              <thead>
                <tr className="text-center">
                  <th></th>
                  <th className="bg-[#f2c96f] rounded-xl px-4 border-l-2 border-dashed border-[#f2c96f] ">
                    {language === "ENG" ? "LASER" : "레이저"}
                  </th>
                  <th className="bg-[#f2c96f] rounded-xl px-4 border-l-2 border-dashed border-[#f2c96f] ">
                    {language === "ENG" ? "IVH + LASER" : "IVH + 레이저"}
                  </th>
                  <th className="bg-[#f2c96f] rounded-xl px-4 border-l-2 border-dashed border-[#f2c96f] ">
                    ORING-VIA
                  </th>
                  <th className="bg-[#f2c96f] rounded-xl px-4 border-l-2 border-dashed border-[#f2c96f] ">
                    STACK-VIA
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">
                    {language === "ENG" ? "LASER (cotton)" : "LASER(면)"}
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    2
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    2
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    2
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    4
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">
                    {language === "ENG"
                      ? "Lamination (number of times)"
                      : "적층(횟수)"}
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    1
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    2
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    2
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    2
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">
                    {language === "ENG" ? "NC (number of times)" : "NC(횟수)"}
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    1
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    2
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    2
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    2
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">
                    {language === "ENG" ? "number of floors" : "층수"}
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    A TYPE
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    B TYPE
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    C TYPE
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    D TYPE
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">4</td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩900,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩1,100,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    -
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    -
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">6</td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩1,100,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩1,300,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩1,500,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩1,700,000
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">8</td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩1,300,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩1,500,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩1,700,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩1,900,000
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">10</td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩1,500,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩1,700,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩1,900,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩2,100,000
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">12</td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩1,700,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩1,900,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩2,100,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩2,300,000
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">14</td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩1,900,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩2,100,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩2,300,000
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    ₩2,500,000
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">16</td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩2,100,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩2,300,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩2,500,000
                  </td>
                  <td className="bg-white bg-opacity-25 rounded-xl px-4 text-white">
                    ₩2,700,000
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="bg-[#f2c96f] rounded-xl px-4 py-2">
                    {language === "ENG"
                      ? "Expected delivery date (days)"
                      : "예상납기(일)"}
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    8(+1/-1)
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    9(+1/-1)
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    10(+1/-1)
                  </td>
                  <td className="py-2 bg-[#cfcfcf] bg-opacity-50 rounded-xl px-4 text-white">
                    12(+1/-1)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
      </div>
      <div className="relative">
        <img
          src={ImgPcbBottom}
          alt="pcb"
          className="object-cover w-full h-[700px] lg:h-auto"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white shadow-xl p-8 rounded-xl w-[90%] lg:w-auto">
          <h3 className="text-[#005D8D] font-bold text-2xl mb-8">
            {language === "ENG" ? "CONTACT US" : "문의하기"}
          </h3>
          <h1 className="font-bold text-4xl mb-8">
            {language === "ENG"
              ? "Do you want to create a project?"
              : "프로젝트를 만들고 싶나요?"}
          </h1>
          <h3 className="text-[#cfcfcf] font-bold text-3xl mb-4">
            {language === "ENG" ? "Type here..." : "여기에 입력하세요..."}
          </h3>
          <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0">
            <input
              placeholder="Enter your email address"
              className="bg-[#f3f3f3] p-4 w-full lg:w-1/2 lg:mr-4 grow"
            />
            <button className="bg-[#005D8D] px-8 py-4 text-white">
              {language === "ENG" ? "SEND" : "보내다"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
