import React from "react";
import ButtonUp from "../Components/ButtonUp";
import ScrollToTop from "../Helper/ScrollToTop";
import Footer from "../Components/Footer";
import Navbars from "../Components/Navbars";
import ImgContactHeadline from "../Assets/contact us headline.svg";
import Container from "../Components/Container";
import IconGlobe from "../Assets/contact us globe.png";
import IconMailbox from "../Assets/contact us mailbox.png";
import IconPhone from "../Assets/contact us phone.png";
import IconGlobalMap from "../Assets/contact us global map.png";
import IconLocalMap from "../Assets/contact us local map.png";
import ImgIllustration from "../Assets/contact us illustration.svg";
import IconTestimonialCompany from "../Assets/contact us logo testimonial.png";
import { useLanguage } from "../Context/LanguageContext";
import { Helmet } from "react-helmet-async";
import { FaMapLocationDot } from "react-icons/fa6";

export default function ContactUs() {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>
          {language === "ENG"
            ? "PT. Meister Sinergi Indonesia - Contact Us"
            : "PT. Meister Sinergi Indonesia - 문의하기"}
        </title>
      </Helmet>
      <div className="relative">
        <Navbars currentBold={5} />
        <img
          src={ImgContactHeadline}
          alt="headline contact us"
          className="object-cover w-full h-[1000px] lg:h-auto absolute top-0 left-0 -z-10"
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
        />
      </div>
      <Container className={"my-12 lg:my-20"}>
        <div className="text-white lg:mx-20">
          <h1 className="font-bold text-4xl lg:text-5xl mb-4">
            {language === "ENG" ? "Contact us" : "문의하기"}
          </h1>
          <p>
            {language === "ENG"
              ? "Ready to get started?"
              : "시작할 준비가 되셨나요?"}
            <br />
            {language === "ENG"
              ? "Your personal data will not be published."
              : "귀하의 개인 데이터는 공개되지 않습니다."}
          </p>
        </div>
        <div className="flex flex-col lg:flex-row my-12 lg:my-20 shadow-xl rounded-3xl">
          <div className="flex flex-col bg-[#BFD9E7] py-12 lg:py-20 px-16 rounded-t-3xl lg:rounded-r-none lg:rounded-s-3xl">
            <div className="flex flex-row items-center space-x-4 font-bold mb-4">
              <img
                src={IconGlobe}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="icon contact us"
                className="w-1/4 sm:w-1/5"
              />
              <h2>{language === "ENG" ? "Our address" : "우리 주소"}</h2>
            </div>
            <p className="mb-8">
              {language === "ENG"
                ? "Jl. Jajaway No.7 Ciumbuleuit, Kec. Cidadap, Kota Bandung, Jawa Barat 40142"
                : "Jl. Jajaway No.7 Ciumbuleuit, Kec. 시다답, 반둥 시, 서부 자바 40142"}
            </p>
            <div className="flex flex-row items-center space-x-4 font-bold mb-4">
              <img
                src={IconMailbox}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="icon contact us"
                className="w-1/4 sm:w-1/5"
              />
              <h2>{language === "ENG" ? "Our mailbox" : "우리의 사서함"}</h2>
            </div>
            <p className="mb-8">info@meistersinergi.com</p>
            <div className="flex flex-row items-center space-x-4 font-bold mb-4">
              <img
                src={IconPhone}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
                alt="icon contact us"
                className="w-1/4 sm:w-1/5"
              />
              <h2>Our phone</h2>
            </div>
            <p className="mb-8">+8110-2901-2255</p>
            <img
              src={ImgIllustration}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              alt="icon contact us"
              className=""
            />
          </div>
          <div className="flex flex-col bg-white py-12 lg:py-20 px-16 rounded-b-3xl lg:rounded-e-3xl">
            <h1 className="font-bold text-4xl lg:text-5xl mb-8">
              {language === "ENG" ? "Get in touch" : "연락하세요"}
            </h1>
            <p className="text-justify mb-8">
              {language === "ENG"
                ? "Give us a call or drop by anytime, we endeavor to answer all inquiries within 24 hours on business days. We will be happy to answer your questions."
                : "언제든지 전화 또는 방문해주세요. 모든 문의사항은 영업일 기준 24시간 이내에 답변해 드리기 위해 노력하고 있습니다. 귀하의 질문에 기꺼이 답변해 드리겠습니다."}
            </p>
            <input
              placeholder="Your name"
              className="px-4 py-2 border-2 border-[#005D8D] mb-4"
            />
            <input
              placeholder="Your email"
              className="px-4 py-2 border-2 border-[#005D8D] mb-4"
            />
            <textarea
              placeholder="Message..."
              className="px-4 py-2 border-2 border-[#005D8D] mb-4"
            />
            <button className="bg-[#005D8D] text-white w-[50%] lg:w-[25%] py-4 mt-8 hover:bg-opacity-75 self-center lg:self-start">
              {language === "ENG" ? "SEND MESSAGE" : "문자 보내"}
            </button>
          </div>
        </div>
      </Container>
      <div className="lg:my-16 lg:mx-8 overflow-x-auto lg:overflow-x-hidden">
        <img
          src={IconLocalMap}
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="local map contact us"
          className="object-cover min-w-[500px] h-auto lg:w-full"
        />
        <div className="flex flex-row gap-x-4 justify-center items-center p-6">
          <FaMapLocationDot className="sm:h-8 sm:w-8 h-12 w-12" />
          <p className="font-medium">
            {language === "ENG"
              ? "Jl. Jajaway No.7 Ciumbuleuit, Kec. Cidadap, Kota Bandung, Jawa Barat 40142"
              : "Jl. Jajaway No.7 Ciumbuleuit, Kec. 시다답, 반둥 시, 서부 자바 40142"}
          </p>
        </div>
      </div>
      <div className="relative my-20">
        <img
          src={IconGlobalMap}
          onContextMenu={(e) => e.preventDefault()}
          draggable="false"
          alt="global map contact us"
          className="object-cover h-[600px] w-full lg:h-auto"
        />
        <div className="text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full lg:w-auto">
          <h3 className="text-[#005D8D] font-bold text-2xl mb-8">
            {"//"} {language === "ENG" ? " OUR CLIENTS" : " 우리의 클라이언트"}
          </h3>
          <h1 className="font-bold text-3xl lg:text-5xl">
            {language === "ENG"
              ? "We are trusted 15+ countries worldwide"
              : "우리는 전 세계 15개 이상의 국가에서 신뢰를 받고 있습니다."}
          </h1>
          <div className="bg-white shadow-xl lg:mx-0 mx-8 py-8 px-8 my-12 rounded-xl">
            <p>
              {language === "ENG"
                ? '"Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."'
                : '"인내. 무한한 인내심. 지름길이 없습니다. 아주 잘 생각되고 명확한 의사소통. 명확한 이정표, 마감일 및 빠른 작업. 클라이언트가 부주의하더라도. 가장 중요한 부분은...항상 훌륭하고 독창적인 아이디어로 문제를 해결하는 것입니다!."'}
            </p>
            <img
              src={IconTestimonialCompany}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
              alt="testimonial contact us"
              className="mx-auto w-[30%] lg:w-[10%] my-2 lg:my-8"
            />
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
      <ButtonUp />
    </>
  );
}
