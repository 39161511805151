import React, { createContext, useState, useContext, useEffect } from "react";

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const storedLanguage = sessionStorage.getItem("language");
    return storedLanguage ? storedLanguage : "ENG";
  });

  useEffect(() => {
    sessionStorage.setItem("language", language);
  }, [language]);

  const handleLanguage = () => {
    setLanguage(language === "ENG" ? "KOR" : "ENG");
  };

  return (
    <LanguageContext.Provider value={{ language, handleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
